import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import NavigationBar from 'apollo-react/components/NavigationBar';
import Loader from 'apollo-react/components/Loader';
import {GetURLQuery} from '../../../../common/utils/queryParams';
import {redirectAuthentication} from '../../slices/RedirectPageSlice';
import {RootState} from '../../../../app/store';
import {fetchUserRequest} from '../../../projects/slices/userDetailsSlice';
import {getActiveuser} from '../../../projects/selectors/userDetailsSelector';
import {CONSTANTS} from '../../../../common/constants';
import {addGAEvent} from '../../../../services/googleAnalytics';

const RedirectAuthentication = ({history}: any) => {
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const routeCode = GetURLQuery(location.search, 'code');
  const dispatch = useDispatch();
  // set Default to 1 if userid not exist
  const {userid = 1} = useSelector(getActiveuser);

  const {
    authenticationSuccess,
    loading,
    error: errorOnAuthorizing,
  } = useSelector((state: RootState) => state.redirectAuthentication);

  useEffect(() => {
    dispatch(redirectAuthentication({routeCode}));
  }, [routeCode, dispatch]);

  useEffect(() => {
    if (errorOnAuthorizing && !loading) {
      setErrorMessage(errorOnAuthorizing);
      addGAEvent({
        category: 'User',
        event: 'LOGIN',
        action: 'USER_LOGIN_FAILURE',
        label: 'USER_LOGIN_FAILURE',
      });
      history.push('/accessRestricted');
    }
    if (authenticationSuccess && authenticationSuccess.jwtToken) {
      sessionStorage.setItem('authToken', authenticationSuccess.jwtToken);
      dispatch(fetchUserRequest(userid));
      addGAEvent({
        category: 'User',
        event: 'LOGIN',
        action: 'USER_LOGIN_SUCCESS',
        label: 'USER_LOGIN_SUCCESS',
      });
      history.push('/');
    }
  }, [dispatch, authenticationSuccess, errorOnAuthorizing, loading, history, userid]);
  const logoProps = {
    text: CONSTANTS.LABELS.IQVIA_TRIAL_DESIGNER,
    alt: 'Apollo logo alt text',
    onClick: () => history.push('/'),
  };
  const navClick = ({pathname}: any = {}) => {
    pathname && history.push(pathname);
  };

  return (
    <>
      <NavigationBar
        id="navigation"
        logoProps={logoProps}
        waves
        onClick={navClick}
        data-testid="redirect-authentication"
      />
      {!errorMessage && <Loader />}
    </>
  );
};

export default RedirectAuthentication;
