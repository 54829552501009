import {put, takeEvery, call, select} from 'redux-saga/effects';
import {
  fetchSuccess,
  fetchFailure,
  fetchRequest,
  updateRoleRequest,
  updateRequestFailure,
  updateRequestSuccess,
  updateUserStatusRequest,
} from '../slices/UserManagementTableSlice';
import {ENDPOINTS} from '../../../services/endpoint';
import {callApi} from '../../../services/api';
import {PayloadAction} from '@reduxjs/toolkit';
import {getActiveuser} from '../../projects/selectors/userDetailsSelector';
import {
  ACTION_USER_ACTIVATION,
  ACTION_USER_ROLE_CHANGE_ROLE,
  isUserActive,
} from '../utils/userManagmentTableCommon';

export default function* watchGetUserManagementList() {
  yield takeEvery(fetchRequest, getUserManagementList);
  yield takeEvery(updateRoleRequest, updateUserRole);
  yield takeEvery(updateUserStatusRequest, updateUserStatus);
}

export function* getUserManagementList(
  action: PayloadAction<{
    page: string;
    limit: number;
    userid: string;
    tenantid: string;
    sortedColumn: string;
    sortOrder: string;
    filters: string;
  }>,
) {
  try {
    const {page, limit, tenantid, sortedColumn, sortOrder, filters} = action.payload;

    let url = `${ENDPOINTS.USER_MANAGEMENT_INFO}?tenantid=${tenantid}&page=${page}&limit=${limit}&sortby=${sortedColumn}&order=${sortOrder}`;
    /* istanbul ignore next */
    if (filters) {
      url = `${url}&${filters}`;
    }
    const result = yield call(callApi, url);

    yield put(fetchSuccess(result));
  } catch (error) {
    console.warn(error);
    yield put(fetchFailure(error?.error));
  }
}

export function* updateUserRole(
  action: PayloadAction<{
    oldRoleId: string | number;
    userId: string | number;
    newRoleId: string | number;
    tenantUserRoleId: string | number;
  }>,
) {
  try {
    const {oldRoleId, userId: payloadUserID, newRoleId, tenantUserRoleId} = action.payload;
    const {tenantid, userid} = yield select(getActiveuser);
    const url = `${ENDPOINTS.EDIT_USER_ROLE}?userid=${userid}&tenantid=${tenantid}`;
    const body = {
      oldRoleId,
      userId: payloadUserID,
      newRoleId,
      tenantUserRoleId,
    };
    const data = yield call(callApi, url, {
      method: 'PUT',
      mode: 'cors',
      body: JSON.stringify(body),
    });
    /* istanbul ignore next */
    if (data) {
      yield put(updateRequestSuccess({requestType: ACTION_USER_ROLE_CHANGE_ROLE}));
    }
  } catch (error) {
    console.warn(error);
    yield put(updateRequestFailure({...error?.error, requestType: ACTION_USER_ROLE_CHANGE_ROLE}));
  }
}

export function* updateUserStatus(
  action: PayloadAction<{
    userId: string | number;
    tenantUserRoleId: string | number;
    status: string;
  }>,
) {
  try {
    const {status, userId: payloadUserID} = action.payload;
    const {tenantid, userid} = yield select(getActiveuser);
    const url = `${ENDPOINTS.EDIT_USER_STATUS}?userid=${userid}&tenantid=${tenantid}`;
    /* istanbul ignore next */
    const body = {
      isactivate: isUserActive(status) ? false : true,
      aduserid: payloadUserID,
    };
    const data = yield call(callApi, url, {
      method: 'PUT',
      mode: 'cors',
      body: JSON.stringify(body),
    });
    /* istanbul ignore next */
    if (data) {
      yield put(updateRequestSuccess({requestType: ACTION_USER_ACTIVATION}));
    }
  } catch (error) {
    console.warn(error?.error);
    yield put(updateRequestFailure({...error?.error, requestType: ACTION_USER_ACTIVATION}));
  }
}
