import {map, filter, forEach, values, omit, get, reduceRight, reduce, uniq, find, every} from 'lodash';
import parser from '../../../utils/parser';
import {PosteriorDistributionProps} from '../../../types/MultiLineVIewProps';
import {DOSE_ESC_CONSTANTS} from '../../../../../common/constants/doseEscalation';
import {roundNumber} from '../../../../../common/utils';

const MTD = 'Reported MTD Distribution';
const RP2D = 'Reported RP2D(Recommended Phase 2 Dose) Distribution';
const SAMPLE_SIZE_DIST = 'Sample Size Distribution';
const TRIAL_DURATION_DISTRUBUTION = 'Trial Duration Distribution';
const TOXCURVE = 'compareByToxCurve';
const TARGET_TOXICITY_PROBABILITY = 'Toxicity';
const TARGET_EFFICACY_PROBABILITY = 'Effectiveness';
const PrintEendPoint = 'TOXEFFICACY';
const COHERT_DISTRUBUTION = 'Total # Cohorts Distribution';

const _compareBy = (compareBy: string, compareTo: string): boolean => compareBy === compareTo;

const _generateBlankSpace = (item: Array<any> | number, blankAttribute: any = ' ') =>
  Array.from(Array(Array.isArray(item) ? item.length : item)).map(() => blankAttribute);

export const getKeyName = (value: any, isCompareByToxCurve: boolean): string =>
  isCompareByToxCurve ? value.refName : `${value.refName} (${value.curveName})`;

export const removeSpecicalValuesForMTD = (value: Array<string | number>) => {
  const removeBelowValue = value.slice(1);
  return removeBelowValue.slice(0, -2);
};

export const removeSpecicalValuesForRP2D = (value: Array<string | number>) => {
  const removeEarlyTerm = value.slice(1);
  return removeEarlyTerm.slice(0, -1);
};

export const queryTrailParams = (data: any, key: string) => {
  const parsedData = parser(data);
  const endPoint = find(parsedData.trialParams, {name: key});
  return endPoint?.value || '';
};

export const generateToggleStateForTabs = (tabs: Array<{id: string; label: string}>) =>
  reduce(
    tabs,
    (acc: any, cur: any) => {
      acc = {[cur.id]: {isOpen: true, options: []}, ...acc};
      return acc;
    },
    {},
  );

/**
 *
 * @param category : Category which is been selected
 * @param data : Data from API after filteration
 * @returns Filtered Data
 */
export const getDataSpecficToCategory = (category: string, data: Array<any>) => {
  return new Promise((resolve, reject) => {
    if (!data.length) {
      reject('No Data Found');
    }
    const filteredData = map(data, (v) => {
      const graphData = filter(v.graphData, {name: category});
      return Object.assign({}, v, {graphData: graphData});
    });
    resolve(filteredData);
  });
};
/**
 *
 * @param compareBy : Compare by
 * @param rawData : Data
 * @returns
 */
export const processSampleSizeGraphData = (category: string, compareBy: string, rawData: Array<any>) => {
  const graphData: Array<any> = [];
  forEach(rawData, (v: any) => {
    const referenceData = v.graphData[0].referenceData;
    const designOption = _compareBy(compareBy, TOXCURVE) ? `${v.refName}` : `${v.refName} (${v.curveName})`;
    const formatData = referenceData.reduce(
      (obj: any, item: any) =>
        Object.assign(obj, {
          name: designOption,
          [item.name]: item.value,
        }),
      {},
    );
    graphData.push(formatData);
  });
  return graphData;
};

const generateSampleSizeGraph = (category: string, compareBy: string, rawData: Array<any>) => {
  const graphData = processSampleSizeGraphData(category, compareBy, rawData);
  const getInputValues = rawData[0]?.graphData[0]?.referenceLegends || [];
  return {
    graphData,
    meta: {
      x: {
        label: `Design Option`,
        unit: category === TRIAL_DURATION_DISTRUBUTION ? '(Days)' : '',
      },
      y: {
        label: category === TRIAL_DURATION_DISTRUBUTION ? `Trial Duration` : `Sample Size`,
      },
      ...(getInputValues &&
        getInputValues.length && {
          toolTip: {label: 'Mean Time(Days)', value: getInputValues},
        }),
    },
  };
};

/**
 *
 * @param category : Category which is been selected
 * @param compareBy : Compare by
 * @param rawData : Data from API after filteration
 * @returns
 */
export const getGraphData = (
  category: string,
  compareBy: string,
  rawData: Array<any>,
  hideSpecialValuesForGraphs: boolean = false,
) => {
  return new Promise((resolve, reject) => {
    try {
      const isSAMPLESIZEDIST = _compareBy(category, SAMPLE_SIZE_DIST);
      const isTRAILDURATIONDIST = _compareBy(category, TRIAL_DURATION_DISTRUBUTION);
      if (isSAMPLESIZEDIST || isTRAILDURATIONDIST) {
        resolve(generateSampleSizeGraph(category, compareBy, rawData));
      } else {
        const isMTD = _compareBy(category, MTD);
        const unit = getUnits(rawData);
        const graphData: Array<any> = [];
        const xValues = get(rawData[0].graphData[0], 'x');
        const yValues = get(rawData[0].graphData[0], 'y');
        const isCompareByToxCurve = _compareBy(compareBy, TOXCURVE);
        const getInputValues = rawData[0]?.graphData[0]?.referenceLegends || [];
        forEach(rawData, (v: any) => {
          const x = v.graphData[0].x;
          const y = v.graphData[0].y;
          let dltsCurve = [];
          if (isCompareByToxCurve && category !== 'Total # Cohorts Distribution') {
            const getDltsCurves = parser(v.curveParams).crv_params;
            dltsCurve = values(omit(getDltsCurves, 'id'));
          }
          let dltRates: Array<string> = [];
          if (dltsCurve.length) {
            if (isMTD) {
              dltRates = hideSpecialValuesForGraphs ? dltsCurve : [null, ...dltsCurve, null, null];
            } else {
              dltRates = dltsCurve;
            }
          }
          const modifedXValues =
            isMTD && hideSpecialValuesForGraphs ? removeSpecicalValuesForMTD(x.values) : x.values;
          const modifedYValues =
            isMTD && hideSpecialValuesForGraphs ? removeSpecicalValuesForMTD(y.values) : y.values;

          const name = getKeyName(v, isCompareByToxCurve);
          if (!graphData.length) {
            modifedXValues.forEach((value: number | string, index: number) => {
              graphData.push({
                [x.name]: value,
                [`S${v.projSessionId}, ${name}`]: modifedYValues[index],
                ...(dltsCurve.length ? {'True Toxicity Prob': dltRates[index] || null} : {}),
              });
            });
          } else {
            forEach(graphData, (value, index) => {
              graphData[index][`S${v.projSessionId}, ${name}`] = modifedYValues[index];
              if (dltsCurve.length) {
                graphData[index]['True Toxicity Prob'] = dltRates[index] || null;
              }
            });
          }
        });
        resolve({
          graphData,
          meta: {
            x: {
              ...(xValues.referenceLevel &&
                xValues.referenceLevel.length && {
                  referenceLabels:
                    isMTD && hideSpecialValuesForGraphs
                      ? removeSpecicalValuesForMTD(xValues.referenceLevel)
                      : xValues.referenceLevel,
                }),
              label: xValues.name,
              unit,
            },
            y: {
              ...(yValues.referenceLevel &&
                yValues.referenceLevel.length && {referenceLabels: yValues.referenceLevel}),
              label: yValues.name,
              dataKeys: filter(Object.keys(graphData[0]), (v) => v !== xValues.name),
            },
            ...(getInputValues &&
              getInputValues.length && {
                toolTip: {label: 'Mean Time(Days)', value: getInputValues},
              }),
            ...(isMTD && {
              graphHeaderInfo:
                "It's the % of simulations which chose the respective dose level as the MTD; the height at dose X is (Number of simulations choosing dose X as MTD) / (total number of simulations)",
            }),
          },
        });
      }
    } catch (e) {
      reject(e.message);
    }
  });
};

export const getGraphDataForRP2D = (
  category: string,
  compareBy: string,
  rawData: Array<any>,
  hideSpecialValuesForGraphs: boolean = false,
) => {
  return new Promise((resolve, reject) => {
    try {
      const isSAMPLESIZEDIST = _compareBy(category, SAMPLE_SIZE_DIST);
      const isTRAILDURATIONDIST = _compareBy(category, TRIAL_DURATION_DISTRUBUTION);
      if (isSAMPLESIZEDIST || isTRAILDURATIONDIST) {
        resolve(generateSampleSizeGraph(category, compareBy, rawData));
      } else {
        const isMTD = _compareBy(category, RP2D);
        const unit = getUnits(rawData);
        const graphData: Array<any> = [];
        const xValues = get(rawData[0].graphData[0], 'x');
        const yValues = get(rawData[0].graphData[0], 'y');
        const isCompareByToxCurve = _compareBy(compareBy, TOXCURVE);
        const EOSTM = queryTrailParams(rawData[0].trialParams, 'EOSTM');
        const isCohertGraph = _compareBy(category, COHERT_DISTRUBUTION);
        const getInputValues = rawData[0]?.graphData[0]?.referenceLegends || [];
        forEach(rawData, (v: any) => {
          const x = v.graphData[0].x;
          const y = v.graphData[0].y;
          const yEffect = v.graphData[0].y?.effectiveness ? v.graphData[0].y.effectiveness : null;
          let dltsCurve = [];
          let efficacyCurves = [];
          if (isCompareByToxCurve) {
            const getDltsCurves = parser(v.curveParams).crv_params;
            const getEfficacyCurves = parser(v.curveParams).crv_params_efficacy;
            dltsCurve = values(omit(getDltsCurves, 'id'));
            efficacyCurves = values(omit(getEfficacyCurves, 'id'));
          }
          let dltRates: Array<string> = [];
          let efficacyRates: Array<string> = [];
          if (dltsCurve.length) {
            if (isMTD) {
              dltRates = hideSpecialValuesForGraphs ? dltsCurve : [null, ...dltsCurve, null];
            } else {
              dltRates = dltsCurve;
            }
          }
          if (efficacyCurves.length) {
            if (isMTD) {
              efficacyRates = hideSpecialValuesForGraphs ? efficacyCurves : [null, ...efficacyCurves, null];
            } else {
              efficacyRates = efficacyCurves;
            }
          }
          const modifedXValues =
            isMTD && hideSpecialValuesForGraphs ? removeSpecicalValuesForRP2D(x.values) : x.values;
          const modifedYValues =
            isMTD && hideSpecialValuesForGraphs ? removeSpecicalValuesForRP2D(y.values) : y.values;

          const name = getKeyName(v, isCompareByToxCurve);
          if (!graphData.length) {
            modifedXValues.forEach((value: number | string, index: number) => {
              graphData.push({
                [x.name]: value,
                [`S${v.projSessionId}, ${name}`]: modifedYValues[index],
                ...(yEffect &&
                  !isCohertGraph && {
                    [`S${v.projSessionId}, ${name} Effectiveness Dashed Line`]: yEffect[index],
                  }),
                ...(dltsCurve.length && !isCohertGraph
                  ? {'True Toxicity Prob': dltRates[index] || null}
                  : {}),
                ...(efficacyCurves.length && !isCohertGraph
                  ? {Effectiveness: efficacyRates[index] || null}
                  : {}),
              });
            });
          } else {
            forEach(graphData, (value, index) => {
              graphData[index][`S${v.projSessionId}, ${name}`] = modifedYValues[index];
              if (!isCohertGraph && yEffect) {
                graphData[index][`S${v.projSessionId}, ${name} Effectiveness Dashed Line`] = yEffect[index];
              }
              if (!isCohertGraph && dltsCurve.length) {
                graphData[index]['True Toxicity Prob'] = dltRates[index] || null;
              }
              if (!isCohertGraph && efficacyCurves.length) {
                graphData[index]['Effectiveness'] = efficacyRates[index] || null;
              }
            });
          }
        });
        resolve({
          graphData,
          meta: {
            x: {
              ...(xValues.referenceLevel &&
                xValues.referenceLevel.length && {
                  referenceLabels:
                    isMTD && hideSpecialValuesForGraphs
                      ? removeSpecicalValuesForRP2D(xValues.referenceLevel)
                      : xValues.referenceLevel,
                }),
              label: xValues.name,
              unit,
            },
            y: {
              ...(yValues.referenceLevel &&
                yValues.referenceLevel.length && {referenceLabels: yValues.referenceLevel}),
              label: yValues.name,
              dataKeys: filter(Object.keys(graphData[0]), (v) => v !== xValues.name),
            },
            eostm: EOSTM || '',
            ...(getInputValues &&
              getInputValues.length && {
                toolTip: {label: 'Mean Time(Days)', value: getInputValues},
              }),
          },
        });
      }
    } catch (e) {
      reject(e.message);
    }
  });
};

/**
 * Format Data for Tiny Chart
 * @param params {id: '', d1:'1', d2: '2'}
 * @returns [{x: 'D1', y: '1'}, {x: 'D2', y: '2'}]
 */
const getDLTRatesDataForTinyChart = (params: any) =>
  Object.entries(params)
    .map((e) => {
      if (e[0] !== 'id') return {x: e[0], y: e[1]};
      else {
        return undefined;
      }
    })
    .filter((e) => e !== undefined);

/**
 *
 * @param referenceData : reference data for getting mean, max, value,
 * * @param isColumns : to identify is data for column or rows,
 * @returns
 */
const getValuesForSampleSizeDist = (
  referenceData: Array<{name: string; value: number | string}>,
  isColumns: boolean = false,
  category?: string,
) => {
  return reduceRight(
    referenceData,
    (a: any, v: any) => {
      a = [
        isColumns ? v.name : category === TRIAL_DURATION_DISTRUBUTION ? roundNumber(v.value, 0) : v.value,
        ...a,
      ];
      return a;
    },
    [],
  );
};

const getTargetProbability = (optionName: string, value: number) =>
  DOSE_ESC_CONSTANTS.DEFAULT_DESIGN_OPTIONS_TOOLTIP.includes(optionName) ? 'toolTip' : value;

/**
 * Get all the unit for the selected results
 * @param rawData Arrat of Results
 * @returns unit <string>
 */
const getUnits = (rawData: Array<any>) => {
  if (!rawData.length) {
    return '';
  }
  const findUnits = uniq(
    reduce(
      rawData,
      (acc: any, cur: any) => {
        const getTrialParams = parser(cur.trialParams).trialParams;
        const unit = filter(getTrialParams, (item: any) => item.name === 'doseUnit');
        acc = [unit[0].value.toLowerCase(), ...acc];
        return acc;
      },
      [],
    ),
  );
  return findUnits.length ? (findUnits.length === 1 ? findUnits[0] : findUnits.join(', ')) : '';
};

/**
 * Generate Data for Rows
 * @param rawData : Data for that specfic tab selected
 * @param isCompareByToxCurve : Is it Tox Curve Selected
 * @param hasReferenceLevel : Does it have reference level, if has then consider has dose label
 * @returns
 */
const getRows = (
  category: string,
  isCompareByToxCurve: boolean,
  rawData: any,
  hideSpecialValuesForGraphs: boolean,
  endPoint: string,
) => {
  try {
    if (!rawData.length) {
      return [];
    }
    const isSAMPLESIZEDIST = _compareBy(category, SAMPLE_SIZE_DIST);
    const isTRAILDURATIONDIST = _compareBy(category, TRIAL_DURATION_DISTRUBUTION);
    const isMTD = _compareBy(category, MTD);
    if (isSAMPLESIZEDIST || isTRAILDURATIONDIST) {
      const rows = map(rawData, (value) => {
        const name = getKeyName(value, isCompareByToxCurve);
        const referenceData = value.graphData[0].referenceData;
        const getValues = getValuesForSampleSizeDist(referenceData);
        const getDltsCurves = parser(value.curveParams).crv_params;
        const getTrialParams = parser(value.trialParams).trialParams;
        const getTargetProbabiltyToxicity = filter(
          getTrialParams,
          (item: any) => item.name === 'targetProbabilityToxicity',
        );
        const targetProbabilitlyAdjuster = getTargetProbability(
          value.designOption,
          getTargetProbabiltyToxicity[0].value,
        );
        return {
          name: `Session ${value?.projSessionId} ${name}`,
          values: [targetProbabilitlyAdjuster, ...getValues],
          designOption: value.designOption,
          ...(!isCompareByToxCurve && {tinyChartValues: getDLTRatesDataForTinyChart(getDltsCurves)}),
        };
      });
      if (isCompareByToxCurve) {
        const getDltsCurves = parser(rawData[0].curveParams).crv_params;
        return [
          {
            name: `${rawData[0].curveName}`,
            values: ['-', '-', '-', '-', '-'],
            tinyChartValues: getDLTRatesDataForTinyChart(getDltsCurves),
          },
          ...rows,
        ];
      }
      return rows;
    } else {
      const rows = map(rawData, (value) => {
        const name = getKeyName(value, isCompareByToxCurve);
        const getDltsCurves = !isCompareByToxCurve && parser(value.curveParams).crv_params;
        const getTrialParams = parser(value.trialParams).trialParams;
        const getTargetProbabiltyToxicity = filter(
          getTrialParams,
          (item: any) => item.name === 'targetProbabilityToxicity',
        );
        const targetProbabilitlyAdjuster = getTargetProbability(
          value.designOption,
          getTargetProbabiltyToxicity[0].value,
        );
        return {
          name: `Session ${value?.projSessionId} ${name}`,
          values:
            isMTD && hideSpecialValuesForGraphs
              ? [targetProbabilitlyAdjuster, ...removeSpecicalValuesForMTD(value.graphData[0].y.values)]
              : [targetProbabilitlyAdjuster, ...value.graphData[0].y.values],
          designOption: value.designOption,
          ...(!isCompareByToxCurve && {tinyChartValues: getDLTRatesDataForTinyChart(getDltsCurves)}),
        };
      });
      if (isCompareByToxCurve) {
        const getDltsCurves = parser(rawData[0].curveParams).crv_params;
        const getDltRates = isCompareByToxCurve ? values(omit(getDltsCurves, 'id')) : [];
        if (category === 'Total # Cohorts Distribution') {
          const blankSpace = _generateBlankSpace(rawData[0].graphData[0].y.values, '-');
          return [
            {
              name: `${rawData[0].curveName}`,
              values: ['-', ...blankSpace],
              tinyChartValues: getDLTRatesDataForTinyChart(getDltsCurves),
            },
            ...rows,
          ];
        }
        return [
          {
            name: `True Toxicity Prob. (${rawData[0].curveName})`,
            values:
              isMTD && !hideSpecialValuesForGraphs
                ? ['-', '-', ...getDltRates, '-', '-']
                : ['-', ...getDltRates],
            tinyChartValues: getDLTRatesDataForTinyChart(getDltsCurves),
          },
          ...rows,
        ];
      }
      return rows;
    }
  } catch (e) {
    throw e;
  }
};

/**
 * Generate Data for Rows For RP2D
 * @param rawData : Data for that specfic tab selected
 * @param isCompareByToxCurve : Is it Tox Curve Selected
 * @param hasReferenceLevel : Does it have reference level, if has then consider has dose label
 * @returns
 */
const getRowsRP2D = (
  category: string,
  isCompareByToxCurve: boolean,
  rawData: any,
  hideSpecialValuesForGraphs: boolean,
  endPoint: string,
) => {
  try {
    if (!rawData.length) {
      return [];
    }
    const isSAMPLESIZEDIST = _compareBy(category, SAMPLE_SIZE_DIST);
    const isTRAILDURATIONDIST = _compareBy(category, TRIAL_DURATION_DISTRUBUTION);
    const isMTD = _compareBy(category, RP2D);
    const isCohertGraph: boolean = _compareBy(category, COHERT_DISTRUBUTION);
    if (isSAMPLESIZEDIST || isTRAILDURATIONDIST) {
      const rows = map(rawData, (value) => {
        const name = getKeyName(value, isCompareByToxCurve);
        const referenceData = value.graphData[0].referenceData;
        const getValues = getValuesForSampleSizeDist(referenceData, false, category);
        const getDltsCurves = parser(value.curveParams).crv_params;
        const getTrialParams = parser(value.trialParams).trialParams;
        const getEfficacyCurves = !isCompareByToxCurve && parser(value.curveParams).crv_params_efficacy;
        const getTargetProbabiltyToxicity = filter(
          getTrialParams,
          (item: any) => item.name === 'targetProbabilityToxicity',
        );
        const getTargetEfficacyProbabilty = filter(
          getTrialParams,
          (item: any) => item.name === 'target2ndParamProb',
        );
        return {
          name: `Session ${value?.projSessionId} ${name}`,
          hasMutlipleTinyCharts: true,
          values: [getTargetProbabiltyToxicity[0].value, getTargetEfficacyProbabilty[0].value, ...getValues],
          ...(!isCompareByToxCurve && {
            tinyChartValues: [
              [getDLTRatesDataForTinyChart(getDltsCurves)],
              [getDLTRatesDataForTinyChart(getEfficacyCurves)],
            ],
          }),
        };
      });
      if (isCompareByToxCurve) {
        const getDltsCurves = parser(rawData[0].curveParams).crv_params;
        const getEfficacyCurves = parser(rawData[0].curveParams).crv_params_efficacy;
        return [
          {
            name: `${rawData[0].curveName}`,
            values: ['-', '-', '-', '-', '-', '-'],
            hasMutlipleTinyCharts: true,
            tinyChartValues: [
              [getDLTRatesDataForTinyChart(getDltsCurves)],
              [getDLTRatesDataForTinyChart(getEfficacyCurves)],
            ],
          },
          ...rows,
        ];
      }
      return rows;
    } else {
      const tableData: {
        tinyChartValues?: any;
        hasMutlipleTinyCharts?: any;
        name: string;
        values: any;
        subTitle?: string;
        class?: string;
      }[] = [];
      forEach(rawData, (value) => {
        const name = getKeyName(value, isCompareByToxCurve);
        const getDltsCurves = !isCompareByToxCurve && parser(value.curveParams).crv_params;
        const getEfficacyCurves = !isCompareByToxCurve && parser(value.curveParams).crv_params_efficacy;
        const getTrialParams = parser(value.trialParams).trialParams;
        const getTargetProbabiltyToxicity = filter(
          getTrialParams,
          (item: any) => item.name === 'targetProbabilityToxicity',
        );
        const getTargetEfficacyProbabilty = filter(
          getTrialParams,
          (item: any) => item.name === 'target2ndParamProb',
        );
        const yEffect = value.graphData[0].y?.effectiveness ? value.graphData[0].y.effectiveness : [];
        if (yEffect.length) {
          const blankSpace = _generateBlankSpace(value.graphData[0].y.values);
          tableData.push(
            {
              name: `Session ${value?.projSessionId} ${name}`,
              values: [
                getTargetProbabiltyToxicity[0].value,
                getTargetEfficacyProbabilty[0].value,
                ...blankSpace,
              ],
              ...(!isCompareByToxCurve && {
                tinyChartValues: [
                  [getDLTRatesDataForTinyChart(getDltsCurves)],
                  [getDLTRatesDataForTinyChart(getEfficacyCurves)],
                ],
                hasMutlipleTinyCharts: true,
              }),
            },
            {
              name: `# DLTs`,
              values: ['-', '-', ...value.graphData[0].y.values],
              class: 'nested-child-row',
            },
            {
              name: `# Effectiveness`,
              values: ['-', '-', ...yEffect],
              class: 'nested-child-row',
            },
          );
        } else {
          tableData.push({
            name: `Session ${value?.projSessionId} ${name}`,
            values:
              isMTD && hideSpecialValuesForGraphs
                ? [
                    getTargetProbabiltyToxicity[0].value,
                    getTargetEfficacyProbabilty[0].value,
                    ...removeSpecicalValuesForRP2D(value.graphData[0].y.values),
                  ]
                : [
                    getTargetProbabiltyToxicity[0].value,
                    getTargetEfficacyProbabilty[0].value,
                    ...value.graphData[0].y.values,
                  ],
            ...(!isCompareByToxCurve && {
              tinyChartValues: [
                [getDLTRatesDataForTinyChart(getDltsCurves)],
                [getDLTRatesDataForTinyChart(getEfficacyCurves)],
              ],
              hasMutlipleTinyCharts: true,
            }),
          });
        }
      });
      if (isCompareByToxCurve) {
        const getDltsCurves = parser(rawData[0].curveParams).crv_params;
        const getEfficacyCurves = parser(rawData[0].curveParams).crv_params_efficacy;
        const xValues = rawData[0].graphData[0].x.values;
        const getDltRates = isCompareByToxCurve ? values(omit(getDltsCurves, 'id')) : [];
        const getEfficacyRates = isCompareByToxCurve ? values(omit(getEfficacyCurves, 'id')) : [];
        const blankSpace = _generateBlankSpace(getDltRates);
        const blankSpaceWithXValues = _generateBlankSpace(xValues);
        return [
          {
            name: isCohertGraph ? rawData[0].curveName : `True Probability (${rawData[0].curveName})`,
            colSpan:
              isMTD && !hideSpecialValuesForGraphs
                ? [' ', ' ', ' ', ...blankSpace, ' ']
                : [' ', ' ', ...blankSpaceWithXValues],
            class: isCohertGraph ? '' : 'nested-expanded-parent-row',
            ...(isCohertGraph && {
              tinyChartValues: [
                [getDLTRatesDataForTinyChart(getDltsCurves)],
                [getDLTRatesDataForTinyChart(getEfficacyCurves)],
              ],
              hasMutlipleTinyCharts: true,
            }),
          },
          ...(!isCohertGraph
            ? [
                {
                  name: `Toxicity`,
                  values:
                    isMTD && !hideSpecialValuesForGraphs
                      ? ['-', '-', '-', ...getDltRates, '-']
                      : ['-', '-', ...getDltRates],
                  tinyChartValues: getDLTRatesDataForTinyChart(getDltsCurves),
                  class: 'nested-expanded-child-row',
                },
                {
                  name: `Effectiveness`,
                  values:
                    isMTD && !hideSpecialValuesForGraphs
                      ? ['-', '-', '-', ...getEfficacyRates, '-']
                      : ['-', '-', ...getEfficacyRates],
                  tinyChartValues: getDLTRatesDataForTinyChart(getEfficacyCurves),
                  class: 'nested-expanded-child-row',
                },
              ]
            : []),
          ...tableData,
        ];
      }
      return tableData;
    }
  } catch (e) {
    throw e;
  }
};

const getColumns = (
  category: string,
  isCompareByToxCurve: boolean,
  rawData: Array<any>,
  hideSpecialValuesForGraphs: boolean,
  endPoint: string,
) => {
  try {
    if (!rawData.length) {
      return [];
    }
    const isPrintE = endPoint === PrintEendPoint;
    const isMTD = _compareBy(category, isPrintE ? RP2D : MTD);
    const xValues = get(rawData[0].graphData[0], 'x');
    const isSAMPLESIZEDIST = _compareBy(category, SAMPLE_SIZE_DIST);
    const isTRAILDURATIONDIST = _compareBy(category, TRIAL_DURATION_DISTRUBUTION);
    if (isSAMPLESIZEDIST || isTRAILDURATIONDIST) {
      const referenceData = rawData[0].graphData[0].referenceData;
      const getKeys = getValuesForSampleSizeDist(referenceData, true);
      return [TARGET_TOXICITY_PROBABILITY, ...(isPrintE ? [TARGET_EFFICACY_PROBABILITY] : ''), ...getKeys];
    } else if (isMTD) {
      return hideSpecialValuesForGraphs
        ? [
            TARGET_TOXICITY_PROBABILITY,
            ...(isPrintE ? [TARGET_EFFICACY_PROBABILITY] : []),
            ...(isPrintE
              ? removeSpecicalValuesForRP2D(xValues.referenceLevel)
              : removeSpecicalValuesForMTD(xValues.referenceLevel)),
          ]
        : [
            TARGET_TOXICITY_PROBABILITY,
            ...(isPrintE ? [TARGET_EFFICACY_PROBABILITY] : []),
            ...xValues.referenceLevel,
          ];
    }
    return [
      TARGET_TOXICITY_PROBABILITY,
      ...(isPrintE ? [TARGET_EFFICACY_PROBABILITY] : []),
      ...xValues.values,
    ];
  } catch (e) {
    throw e;
  }
};

/**
 * @param category : Category which is been selected
 * @param compareBy : Compare By (Group by Tox. Curve) or (Group By Design)
 * @param rawData: Data for the specfic tab selected
 * @returns : {rows: Array of key value pair, columns: Array of String}
 */
export const getTableData = (
  category: string,
  compareBy: string,
  rawData: Array<any>,
  hideSpecialValuesForGraphs: boolean = false,
  endPoint: string,
) => {
  return new Promise((resolve, reject) => {
    try {
      const isCompareByToxCurve = _compareBy(compareBy, TOXCURVE);
      const xValues = get(rawData[0].graphData[0], 'x');
      const yValues = get(rawData[0].graphData[0], 'y');
      const columns = getColumns(
        category,
        isCompareByToxCurve,
        rawData,
        hideSpecialValuesForGraphs,
        endPoint,
      );
      const isPrintEendPoint = endPoint === PrintEendPoint;
      const rows = isPrintEendPoint
        ? getRowsRP2D(category, isCompareByToxCurve, rawData, hideSpecialValuesForGraphs, endPoint)
        : getRows(category, isCompareByToxCurve, rawData, hideSpecialValuesForGraphs, endPoint);
      resolve({columns, rows, xLabel: xValues.name, yLabel: yValues.name});
    } catch (e) {
      reject(e.message);
    }
  });
};

const getPosteriorDistributionGraphs = (compareBy: string, rawData: any) => {
  return new Promise((resolve, reject) => {
    try {
      const graphData = processPosteriorDistribution(compareBy, rawData);
      resolve(graphData);
    } catch (e) {
      reject(e.message);
    }
  });
};

/**
 * Generate data for PosteriorDistribution
 * @param compareBy : Compare By (Group by Tox. Curve) or (Group By Design)
 * @param rawData : Data for the specfic tab selected
 * @returns
 */
const processPosteriorDistribution = (compareBy: string, rawData: any): PosteriorDistributionProps => {
  try {
    const isCompareByToxCurve = _compareBy(compareBy, TOXCURVE);
    const graphData: Array<any> = [];
    forEach(rawData, (value) => {
      const optionLabel: string = getKeyName(value, isCompareByToxCurve);
      const graphSrc: {data: any; fileName: string} = value.graphData[0].graph[0];
      graphData.push({
        label: optionLabel,
        value: optionLabel,
        img: graphSrc.data,
        alt: graphSrc.fileName,
      });
    });
    return {
      graphData,
      meta: {
        label: isCompareByToxCurve ? 'Design Options' : 'Design Options',
        options: map(graphData, 'value'),
      },
    };
  } catch (e) {
    throw e;
  }
};

/**
 * Does Graphs is availiable with selected results
 */
const hasGraphToRender = (results: Array<any>) =>
  every(results, (graphs: any) => graphs.graphData && graphs.graphData.length);

export const filterPosteriorDistributionData = (chartData: [any], options: [string]) =>
  filter(chartData, (list: any) => options.includes(list.value) && list);

/**
 * Delay
 * @param ms timeer
 * @returns promise
 */
const delay = (ms: number) => new Promise((resolve) => setTimeout(() => resolve({}), ms));

/**
 * QueryData to generate multiline charts and tables
 * @param category : Category which is been selected
 * @param compareBy : Compare by
 * @param selectedTab : Tab which is Selected
 * @returns {graphs, tableData}
 */
export default function (
  category: string,
  compareBy: string,
  selectedTab: {id: string; label: string; results?: Array<any>},
  hideSpecialValuesForGraphs: boolean = false,
  endPoint: string,
) {
  return new Promise(async (resolve, reject) => {
    const rawData = selectedTab.results || [];
    if (!rawData.length) {
      resolve([]);
    }
    const isPrintEendPoint = endPoint === PrintEendPoint;
    getDataSpecficToCategory(category, rawData)
      .then(async (results: any) => {
        const isGraphsAvailiable = hasGraphToRender(results);
        if (!isGraphsAvailiable) {
          await delay(1000);
          resolve({
            graphs: {graphData: [], meta: {}},
            tableData: {},
            error: `No ${category} results available for the design option simulated with ${selectedTab.id}`,
          });
        }
        if (category === 'Posterior Distribution') {
          const graphs = await getPosteriorDistributionGraphs(compareBy, results);
          await delay(1000);
          resolve({graphs, tableData: {}});
        }
        const [graphData, tableData] = await Promise.all([
          isPrintEendPoint
            ? getGraphDataForRP2D(category, compareBy, results, hideSpecialValuesForGraphs)
            : getGraphData(category, compareBy, results, hideSpecialValuesForGraphs),
          getTableData(category, compareBy, results, hideSpecialValuesForGraphs, endPoint),
        ]);
        // Adding delay to prevent jerking effect while rendering different charts and tables
        await delay(1000);
        resolve({graphs: graphData, tableData});
      })
      .catch((e: any) => reject(`Unable To Process Data ${e.message ? e.messaage : e}`));
  });
}
