/**
 * @data: Stringify Data
 */
const parser = (data: any) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    throw e;
  }
};

export default parser;
