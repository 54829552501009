import {call, put, takeEvery} from 'redux-saga/effects';
import {callApi} from '../../../services/api';
import {ENDPOINTS, REDIRECT_ENV} from '../../../services/endpoint';
import {
  redirectAuthentication as redirectAuthenticationAction,
  redirectAuthenticationSuccess,
  redirectAuthenticationError,
} from '../slices/RedirectPageSlice';
import {addSessionStorage} from '../../../common/utils/useSessionStorage';

export function* watchRedirectAuthentication() {
  yield takeEvery(redirectAuthenticationAction, redirectAuthentication);
}

export function* redirectAuthentication({payload}: any) {
  try {
    const {routeCode} = payload;
    // userid and tenant id to be removed once backend SDA issue is resolved
    const url = `${ENDPOINTS.GET_AUTHENTICATION_TOKEN}?code=${routeCode}&env=${REDIRECT_ENV}`;

    const data = yield call(callApi, url);
    if (data) {
      yield put(redirectAuthenticationSuccess(data));
      yield call(addSessionStorage, 'aunthenticatedUser', 'true');
    }
  } catch (error) {
    console.warn('log in fail', error);
    yield put(redirectAuthenticationError(JSON.stringify(error.message)));
  }
}
