import React from 'react';
import MoreVert from '@material-ui/icons/MoreVert';
import IconMenuButton from 'apollo-react/components/IconMenuButton';
import Tooltip from 'apollo-react/components/Tooltip';
import Settings from '@material-ui/icons/Settings';

const handleClick = (label: any) => () => {
  console.log(`You picked ${label}.`);
};

const menuItems = [
  {
    text: 'Clone',
    onClick: (data: any) => {},
  },
  {
    text: 'Share',
    onClick: handleClick('Share'),
  },
];

export const IconMenuItem = ({row}: any) => {
  return (
    <Tooltip title="Actions">
      <IconMenuButton id="actions" menuItems={menuItems} data={row}>
        <MoreVert />
      </IconMenuButton>
    </Tooltip>
  );
};

export const TenantMenuItem = ({row}: any) => {
  return (
    <Tooltip title="Actions">
      <IconMenuButton
        id="actions"
        data-testid="actionBtn"
        menuItems={[
          {
            text: 'Change Owner',
            'data-testid': 'changeOnwerBtn',
            onClick: (data: any) => {
              const {index, projectid} = row;
              row.onChangeOwnerClick({index, projectid});
            },
          },
          {
            text: 'Mark as Template',
            'data-testid': 'markAsTemplateBtn',
            onClick: (data: any) => {
              const {projectid, projectname} = row;
              row.onChangeMarkTemplateClick({projectid, projectname});
            },
            disabled: row.isAllSessionPublised ? false : true,
          },
        ]}
      >
        <MoreVert />
      </IconMenuButton>
    </Tooltip>
  );
};

export const IconMenuHeader = () => {
  return <Settings fontSize="small" style={{marginTop: 6, marginLeft: 10}} />;
};
