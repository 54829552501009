import React from 'react';
import styles from './Tabs.module.scss';
import {useSelector} from 'react-redux';
import {getActiveUserRole} from '../../selectors/userDetailsSelector';
import DefaultTabs from './Default';
import TenantAdminTabs from './TenantAdmin';

function ProjectTabs() {
  const [value, setValue] = React.useState(0);
  /* istanbul ignore next */
  const handleChangeTab = (event: any, value: any) => {
    setValue(value);
  };
  const activeUserRole = useSelector(getActiveUserRole);
  //   To be revisited when we have more clarifty on other User roles.
  return (
    <div id="tabs" data-testid="tabs" className={styles.tabsContainer}>
      {activeUserRole === 'Tenant Admin' && (
        <TenantAdminTabs value={value} tab="tenantAdmin" handleChangeTab={handleChangeTab} />
      )}
      {activeUserRole !== 'Tenant Admin' && (
        <DefaultTabs value={value} tab="all" handleChangeTab={handleChangeTab} />
      )}
    </div>
  );
}

export default ProjectTabs;
