import React from 'react';
import {useFormikContext} from 'formik';
import {FormikSelect, FormikTextField} from '../../../../common/components/formik-controls/FormikComponents';
import Grid from 'apollo-react/components/Grid';
import MenuItem from 'apollo-react/components/MenuItem';
import {CONSTANTS} from '../../../../common/constants';
import {FormValues, IEngagementType} from '../../types/CreateProjectFormTypes';
import {useSelector} from 'react-redux';
import {getEngagementsSelector} from '../../selectors/CreateProjectSelectors';
import './style.scss';
import {getSelectedEngagement} from '../../utils/CreateProjectUtils';

export default function EngagementType() {
  const {values}: {values: FormValues} = useFormikContext();
  const {setFieldValue, handleChange}: {setFieldValue: Function; handleChange: Function} = useFormikContext();

  const engagementTypes = useSelector(getEngagementsSelector);
  /* istanbul ignore next */
  const onSelectChange = (e: string) => {
    handleChange('engagementType');
    getSelectedEngagement(engagementTypes, e) === 'None' && setFieldValue('projectCode', undefined);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormikSelect
          fullWidth
          name="engagementType"
          label={CONSTANTS.LABELS.ENGAGEMENT_TYPE}
          placeholder="Select"
          canDeselect={false}
          required
          handleChange={onSelectChange}
          helperText=""
        >
          {engagementTypes?.map((type: IEngagementType) => {
            return (
              <MenuItem key={type.id} value={type.id} name={type.name}>
                {type.name}
              </MenuItem>
            );
          })}
        </FormikSelect>
      </Grid>
      {!['', 'None'].includes(getSelectedEngagement(engagementTypes, values.engagementType)) && ( // Project Code is required when the Engagement Type is Billable, Optional when it is Business Development, NA when it is none
        <Grid item xs={6}>
          <FormikTextField
            fullWidth
            name="projectCode"
            label={CONSTANTS.LABELS.PROJECT_CODE}
            placeholder="Enter Value"
            required={getSelectedEngagement(engagementTypes, values.engagementType) === 'Billable'}
            helperText=""
            data-testid="projectCode"
          />
        </Grid>
      )}
    </Grid>
  );
}
