import React, {useEffect, useState} from 'react';
import Tab from 'apollo-react/components/Tab';
import Tabs from 'apollo-react/components/Tabs';
import FilterIcon from 'apollo-react-icons/Filter';
import Undo from 'apollo-react-icons/Undo';
import Button from 'apollo-react/components/Button';
import {ProjectTable} from '../Table';
import styles from './Tabs.module.scss';
import CreateProject from '../CreateProject';
import {useSelector, useDispatch} from 'react-redux';
import {getActivetenant} from '../../selectors/userDetailsSelector';
import {IFilters} from '../../../../common/components/TableFilters/types';
import {resetProjectOwnerDetails} from '../../slices/projectDetailSlice';
function DefaultTabs({value, tab, handleChangeTab}: any) {
  const dispatch = useDispatch();
  const activeTenant = useSelector(getActivetenant);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [tableKey, setTableKey] = useState(0);
  const [filters, setFilters] = useState<IFilters>();

  const clearFilters = () => {
    setTableKey((tableKey) => tableKey + 1);
    setFilters(undefined);
  };

  const config = {
    tabs: [
      {
        label: 'All',
        param: 'all',
      },
      {
        label: 'Shared with me',
        param: 'shared',
      },
      {
        label: 'Templates',
        param: 'templateProject',
      },
    ],
  };
  tab = config.tabs[value].param;

  useEffect(() => {
    dispatch(resetProjectOwnerDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={styles.buttonContainer}>
        {filters && (
          <Button
            variant="secondary"
            icon={<Undo />}
            style={{marginRight: 10}}
            onClick={clearFilters}
            data-testid="clear"
          >
            Clear
          </Button>
        )}
        <Button
          variant="secondary"
          icon={<FilterIcon />}
          style={{marginRight: 10}}
          onClick={() => setShowFilters(!showFilters)}
          data-testid="filters"
        >
          Filters
        </Button>
        {activeTenant?.role?.type === 'Designer' && config.tabs[value].param !== 'templateProject' ? (
          <CreateProject />
        ) : (
          ''
        )}
      </div>
      <Tabs value={value} onChange={handleChangeTab} truncate className={styles.tabsContent}>
        {config.tabs.map((i: any) => (
          <Tab label={i.label} />
        ))}
      </Tabs>

      <div style={{textAlign: 'center'}}>
        <ProjectTable
          tab={tab}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          tableKey={tableKey}
          filters={filters}
          setFilters={setFilters}
        />
      </div>
    </>
  );
}

export default DefaultTabs;
