import React, {useState, useEffect, useRef} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {RootState} from '../../../app/store';
import LanguageIcon from '@material-ui/icons/Language';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckIcon from '@material-ui/icons/Check';
import Typography from 'apollo-react/components/Typography';
import {defaultTenantSelect} from '../../../features/loginPage/slices/DefaultTenantSlice';

import styles from './styles.module.scss';

type Props = {
  options: ReadonlyArray<{tenantNames: string; id: number}>;
  selected: string;
  callBackFromParent: any;
};

// Custom hook to close dropdown menu on clicking ouside of the component
const useClickOutside = (handler: any) => {
  let domNode = useRef<any>(null);

  useEffect(() => {
    let closeHandler = (event: any) => {
      if (!domNode.current.contains(event.target)) {
        handler();
      }
    };
    document.addEventListener('mousedown', closeHandler);
    return () => {
      document.removeEventListener('mousedown', closeHandler);
    };
  });

  return domNode;
};

export default function TenantDropdown({options, selected, callBackFromParent}: Props) {
  const dispatch = useDispatch();
  const userId = useSelector((state: RootState) => state.user?.details?.userID, shallowEqual);
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const optionClick = (value: any) => {
    setOpen(false);
    callBackFromParent(value.id);
  };
  const makeDefault = (defaultTenantId: number) => {
    dispatch(defaultTenantSelect({userId, defaultTenantId}));
  };
  let domNode = useClickOutside(() => {
    setOpen(false);
  });

  return (
    <div className={styles.tenantListContainer} onClick={toggle} data-testid="tenantDropdown" ref={domNode}>
      <div className={styles.selectedTenant}>
        <LanguageIcon />
        <div className={styles.tenantText}>{selected}</div>
        <ArrowDropDownIcon />
      </div>
      {open && (
        <ul className={styles.optionsList}>
          <Typography variant="body2">Workspaces</Typography>
          {options.map((option) => (
            <li
              onClick={() => optionClick(option)}
              key={option.id}
              className={styles.tenantListItem}
              data-testid="tenantDropdownList"
            >
              {selected === option.tenantNames ? (
                <RadioButtonCheckedIcon fontSize="small" />
              ) : (
                <RadioButtonUncheckedIcon fontSize="small" />
              )}
              <span className={styles.tenantOptions}>{option.tenantNames}</span>
              {selected === option.tenantNames ? (
                <CheckIcon fontSize="small" />
              ) : (
                <span
                  className={styles.makeDefaultText}
                  onClick={() => makeDefault(option.id)}
                  data-testid="listTenant"
                >
                  Make Default
                </span>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
