import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
  addSuccess: '',
  editSuccess: '',
  loading: false,
  error: '',
  organization: {
    message: '',
    data: [],
  },
};

const addTenantSlice = createSlice({
  name: 'addTenant',
  initialState: initialState,
  reducers: {
    addTenant: (state, action) => {
      state.loading = true;
    },
    addTenantSuccess: (state, action) => {
      state.loading = false;
      state.error = '';
      state.addSuccess = action.payload;
    },
    addTenantError: (state, action) => {
      state.loading = false;
      state.addSuccess = '';
      state.error = action.payload;
    },
    resetAddTenant: (state) => {
      state.addSuccess = '';
      state.error = '';
      state.loading = false;
    },
    fetchOrganization: (state) => {
      state.loading = true;
    },
    fetchOrganizationSuccess: (state, action) => {
      state.loading = false;
      /* istanbul ignore next */
      state.organization = action.payload.data || [];
    },
    fetchOrganizationError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    editTenant: (state, action) => {
      state.loading = true;
    },
    editTenantSuccess: (state, action) => {
      state.loading = false;
      state.error = '';
      state.editSuccess = action.payload;
    },
    editTenantError: (state, action) => {
      state.loading = false;
      state.editSuccess = '';
      state.error = action.payload;
    },
    resetEditTenant: (state) => {
      state.editSuccess = '';
      state.error = '';
      state.loading = false;
    },
  },
});

export const {
  addTenant,
  addTenantSuccess,
  addTenantError,
  resetAddTenant,
  fetchOrganization,
  fetchOrganizationError,
  fetchOrganizationSuccess,
  editTenant,
  editTenantSuccess,
  editTenantError,
  resetEditTenant,
} = addTenantSlice.actions;

export default addTenantSlice.reducer;
