import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ProjectListState} from '../types/ProjectListType';

export const initialState: ProjectListState = {
  data: [],
  isLoading: false,
  error: '',
  totalRecords: {
    count: 0,
  },
  availableProjectOwners: [],
  pageIndex: 1,
  projectDesigners: [],
  userMessage: false,
};

export const projectListSlice = createSlice({
  name: 'projectlist',
  initialState: initialState as ProjectListState,
  reducers: {
    fetchRequest: (state: ProjectListState, action) => {
      state.isLoading = true;
    },
    fetchSuccess: (state: ProjectListState, action: PayloadAction<ProjectListState>) => {
      const {
        payload: {
          data,
          totalRecords: {count},
          pageIndex,
          availableProjectOwners,
        },
      } = action;

      // Add UI-specific extra attributes to the data received from API.
      const computedData = data.map((project: any, index: number) => ({
        ...project,
        index: index,
        isSelected: false,
        isTemplateProject: project.isTemplateProject ? 'Yes' : 'No',
      }));
      state.data = computedData;
      state.availableProjectOwners = availableProjectOwners;
      state.totalRecords.count = count;
      state.pageIndex = pageIndex;
      state.isLoading = false;
    },
    fetchFailure: (state: ProjectListState, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setProjectRowSelected: (state, action) => {
      const {projectid} = action.payload;

      // Set isSelected flag based on projectid
      let computedData = state.data.map((row) => ({...row, isSelected: row.projectid === projectid}));
      state.data = computedData;
    },
    updateProjectOwner: (state, action) => {
      state.isLoading = true;
    },
    updateProjectOwnerSuccess: (state, action) => {
      state.userMessage = true;
      state.isLoading = false;
    },
    updateProjectOwnerFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getProjectDesigners: (state, action) => {
      state.isLoading = true;
    },
    updateProjectDesigners: (state, action) => {
      state.isLoading = false;
      state.projectDesigners = action.payload;
    },
    closeBanner: (state, action: any) => {
      state.userMessage = false;
    },
    updateTemplateProject: (state, action) => {
      state.isLoading = true;
    },
    updateTemplateProjectSuccess: (state, action) => {
      state.userMessage = true;
      state.isLoading = false;
    },
    updateTemplateProjectFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchRequest,
  fetchFailure,
  fetchSuccess,
  updateProjectOwner,
  getProjectDesigners,
  updateProjectDesigners,
  updateProjectOwnerSuccess,
  updateProjectOwnerFailure,
  setProjectRowSelected,
  closeBanner,
  updateTemplateProject,
  updateTemplateProjectSuccess,
  updateTemplateProjectFailure,
} = projectListSlice.actions;
export default projectListSlice.reducer;
