import {string, object, mixed, boolean} from 'yup';
import {CONSTANTS} from '../../constants';

export type FormValues = {
  subject: string;
  message: string;
  attachment?: FileType | undefined;
  emailMeCopy?: boolean | undefined;
};

export type FileType = {
  name: string;
  type: string;
  size: number;
  loading: boolean;
};

export interface CompProps {
  handleFormSubmit: (values: any) => void;
  closeModal: (close: boolean) => void;
  handleFormDirty: (dirty: boolean) => void;
}

export const SubmitFeedbackFormSchema = () =>
  object().shape({
    subject: string()
      .max(80, CONSTANTS.VALIDATION_MESSAGES.FEEDBACK_SUBJECT_TOO_LONG)
      .required(CONSTANTS.VALIDATION_MESSAGES.REQUIRED),
    message: string()
      .max(800, CONSTANTS.VALIDATION_MESSAGES.FEEDBACK_MESSAGE_TOO_LONG)
      .required(CONSTANTS.VALIDATION_MESSAGES.REQUIRED),
    attachment: mixed(),
    emailMeCopy: boolean(),
  });

export type FeedbackFormType = {
  openFeedbackForm: Function;
  canShowFeedbackOption?: boolean | undefined;
};
