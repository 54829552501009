import {DateCell, ProjectCell, therapeuticAreaCell, SponsorCell, OwnerCell} from './TableCustomCells';

import {COLUMNS} from '../../../../common/constants/tableColumns';
import {IconMenuHeader, TenantMenuItem} from './IconMenu';
import {
  compareDates,
  compareNumbers,
  compareStrings,
  createStringSearchFilter,
  dateFilter,
  numberSearchFilter,
} from 'apollo-react/components/Table';
import {
  TextFieldFilter,
  DateFilter,
  createStringArraySearchFilter,
  IntegerFilter,
  CustomSelectFilterComponent,
} from '../../../../common/components/TableFilters';

export const columnsConfiguration: any = [
  {
    header: COLUMNS.PROJECT_NAME.label,
    accessor: COLUMNS.PROJECT_NAME.accessor,
    customCell: ProjectCell,
    width: 177,
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter(COLUMNS.PROJECT_NAME.accessor),
    filterComponent: TextFieldFilter,
  },
  {
    header: COLUMNS.ENGAGEMENT_TYPE.label,
    accessor: COLUMNS.ENGAGEMENT_TYPE.accessor,
    width: 177,
    sortFunction: compareStrings,
    filterFunction: createStringArraySearchFilter(COLUMNS.ENGAGEMENT_TYPE.accessor),
    filterComponent: CustomSelectFilterComponent,
  },
  {
    header: COLUMNS.DATE_CREATED.label,
    accessor: COLUMNS.DATE_CREATED.accessor,
    customCell: DateCell,
    width: 177,
    sortFunction: compareDates,
    filterFunction: dateFilter(COLUMNS.DATE_CREATED.accessor),
    filterComponent: DateFilter,
  },
  {
    header: COLUMNS.SPONSOR.label,
    accessor: COLUMNS.SPONSOR.accessor,
    customCell: SponsorCell,
    width: 177,
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter(COLUMNS.SPONSOR.accessor),
    filterComponent: TextFieldFilter,
  },
  {
    header: COLUMNS.OWNER.label,
    accessor: COLUMNS.OWNER.accessor,
    width: 200,
    sortFunction: compareStrings,
    filterFunction: createStringArraySearchFilter(COLUMNS.OWNER.accessor),
    filterComponent: CustomSelectFilterComponent,
  },
  {
    header: COLUMNS.PROJECT_OBJECTIVE.label,
    accessor: COLUMNS.PROJECT_OBJECTIVE.accessor,
    width: 177,
    sortFunction: compareStrings,
    filterFunction: createStringArraySearchFilter(COLUMNS.PROJECT_OBJECTIVE.accessor),
    filterComponent: CustomSelectFilterComponent,
  },
  {
    header: COLUMNS.THERAPEUTIC_AREA.label,
    accessor: COLUMNS.THERAPEUTIC_AREA.accessor,
    customCell: therapeuticAreaCell,
    width: 177,
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter(COLUMNS.THERAPEUTIC_AREA.accessor),
    filterComponent: TextFieldFilter,
  },
];

export const tenantAdminColumnsConf: any = [
  {
    header: COLUMNS.PROJECT_NAME.label,
    accessor: COLUMNS.PROJECT_NAME.accessor,
    width: 177,
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter(COLUMNS.PROJECT_NAME.accessor),
    filterComponent: TextFieldFilter,
  },
  {
    header: COLUMNS.OWNER.label,
    accessor: COLUMNS.OWNER.accessor,
    customCell: OwnerCell,
    width: 200,
    sortFunction: compareStrings,
    filterFunction: createStringArraySearchFilter(COLUMNS.OWNER.accessor),
    filterComponent: CustomSelectFilterComponent,
  },
  {
    header: COLUMNS.DATE_CREATED.label,
    accessor: COLUMNS.DATE_CREATED.accessor,
    customCell: DateCell,
    width: 177,
    sortFunction: compareDates,
    filterFunction: dateFilter(COLUMNS.DATE_CREATED.accessor),
    filterComponent: DateFilter,
  },
  {
    header: COLUMNS.SPONSOR.label,
    accessor: COLUMNS.SPONSOR.accessor,
    customCell: SponsorCell,
    width: 177,
    sortFunction: compareStrings,
    filterFunction: createStringSearchFilter(COLUMNS.SPONSOR.accessor),
    filterComponent: TextFieldFilter,
  },
  {
    header: COLUMNS.PROJECT_MEMBERS.label,
    accessor: COLUMNS.PROJECT_MEMBERS.accessor,
    width: 125,
    sortFunction: compareNumbers,
    align: 'center',
    filterFunction: numberSearchFilter(COLUMNS.PROJECTS.accessor),
    filterComponent: IntegerFilter,
  },
  {
    header: COLUMNS.TEMPLATE_PROJECT.label,
    accessor: COLUMNS.TEMPLATE_PROJECT.accessor,
    width: 125,
    sortFunction: compareStrings,
    align: 'center',
    filterFunction: numberSearchFilter(COLUMNS.TEMPLATE_PROJECT.accessor),
    filterComponent: IntegerFilter,
  },
  {
    header: IconMenuHeader(),
    accessor: 'action',
    width: 25,
    customCell: TenantMenuItem,
  },
];

export const getProjectTableConfig = (name = 'all') => {
  if (name === 'tenantAdmin') {
    return tenantAdminColumnsConf;
  } else {
    return columnsConfiguration;
  }
};

export const getRowsPerPageOptions = (size: number) => {
  const TotalLength = Math.round(size / 10) * 10;
  const rowsPerPage = [];
  for (let i = 1; i <= TotalLength / 10; i++) {
    if (i <= 3) {
      rowsPerPage.push(i * 10);
    } else {
      break;
    }
  }
  return rowsPerPage;
};
