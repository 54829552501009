import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
  authenticationSuccess: {
    jwtToken: '',
    userId: '',
    tenantId: '',
    role: '',
  },
  loading: false,
  error: '',
};
export const redirectAuthenticationSlice = createSlice({
  name: 'redirectAuthentication',
  initialState: initialState,
  reducers: {
    redirectAuthentication: (state, action) => {
      state.loading = true;
    },
    redirectAuthenticationSuccess: (state, action) => {
      state.loading = false;
      state.authenticationSuccess.jwtToken = action.payload?.jwtToken;
      state.authenticationSuccess.userId = action.payload?.data[0]?.userid;
      state.authenticationSuccess.tenantId = action.payload?.data[0]?.tenantid;
      state.authenticationSuccess.role = action.payload?.data[0]?.role;
    },
    redirectAuthenticationError: (state, action) => {
      state.loading = false;
      state.error = action.payload || 'Login Failed';
    },
  },
});

export const {redirectAuthentication, redirectAuthenticationSuccess, redirectAuthenticationError} =
  redirectAuthenticationSlice.actions;

export default redirectAuthenticationSlice.reducer;
