import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {RootState} from '../../../../app/store';
import Modal from 'apollo-react/components/Modal';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Checkbox from 'apollo-react/components/Checkbox';
import Loader from 'apollo-react/components/Loader';
import {
  setSessionDefaultTenant,
  setActiveTenant,
} from '../../../../features/projects/slices/userDetailsSlice';
import {defaultTenantSelect} from '../../slices/DefaultTenantSlice';
import {CONSTANTS} from '../../../../common/constants';

import styles from './styles.module.scss';

const DefaultTenantSelect = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tenantList = useSelector((state: RootState) => state.user?.details?.tenants, shallowEqual);
  const {
    defaultTenantSelectSuccess: selectSuccess,
    loading,
    error,
  } = useSelector((state: RootState) => state.defaultTenantSelect);
  const userId = useSelector((state: RootState) => state.user?.details?.userID, shallowEqual);

  const [saveAsDefault, setSaveAsDefault] = useState(true);
  const [selectedOption, setSelectedOption] = useState<any>('');
  const [defaultSelectError, setDefaultSelectError] = useState<any>('');

  useEffect(() => {
    if (error) {
      setDefaultSelectError(error);
    }
    if (selectSuccess) {
      history.push('/');
    }
  }, [dispatch, error, history, selectSuccess]);

  const handleNext = () => {
    if (!selectedOption) return;

    // Adding tenantid to sessionStorage & shall be sent in every API request
    sessionStorage.setItem('tenantid', selectedOption);
    dispatch(setSessionDefaultTenant({tenantId: selectedOption}));
    dispatch(setActiveTenant(selectedOption));

    if (saveAsDefault) {
      dispatch(defaultTenantSelect({userId: userId, defaultTenantId: selectedOption}));
    } else {
      history.push('/');
    }
  };

  const handleCancel = () => {
    sessionStorage.setItem('aunthenticatedUser', '');
    history.push('/');
  };

  const optionClick = (value: any) => {
    setSelectedOption(value.id);
  };

  const handleSaveAsDefault = (e: any, checked: boolean) => {
    setSaveAsDefault(checked);
  };

  return (
    <>
      {loading && <Loader data-testid="defaultTenantLoader" />}
      {!defaultSelectError && (
        <Modal
          open={true}
          onClose={handleCancel}
          title="Select Workspace"
          data-testid="defaultTenantSelect"
          style={{zIndex: 999999, background: 'white'}}
          buttonProps={[
            {label: 'Cancel', onClick: handleCancel},
            {label: 'Next', onClick: handleNext, disabled: !selectedOption},
          ]}
        >
          <p className={styles.bottomSpacing}>{CONSTANTS.LABELS.ACCESS_MULTIPLE_TENANTS}</p>
          <p className={styles.bottomSpacing}>{CONSTANTS.LABELS.SWITCH_TENANTS}</p>

          <ul className={styles.optionsList}>
            {tenantList &&
              tenantList.length > 0 &&
              tenantList.map((option: any) => (
                <li
                  onClick={() => optionClick(option)}
                  key={option.id}
                  className={styles.tenantListItem}
                  data-testid="tenantDropdownList"
                >
                  {selectedOption === option.id ? (
                    <RadioButtonCheckedIcon fontSize="small" />
                  ) : (
                    <RadioButtonUncheckedIcon fontSize="small" />
                  )}
                  <span className={styles.tenantOptions}>{option.tenantNames}</span>
                </li>
              ))}
          </ul>
          <Checkbox
            label={CONSTANTS.LABELS.SAVE_AS_DEFAULT}
            className={styles.userTypeCheckbox}
            checked={saveAsDefault}
            onChange={handleSaveAsDefault}
            data-testid="saveAsDefaultCheckbox"
          />
        </Modal>
      )}
      {defaultSelectError && (
        <Modal
          open={defaultSelectError}
          variant="error"
          onClose={handleCancel}
          title={CONSTANTS.LABELS.ERROR}
          message={`An error occurred ! ${defaultSelectError}`}
          data-testid="defaultTenantError"
          buttonProps={[
            {label: '', style: {display: 'none'}},
            {label: CONSTANTS.LABELS.EXIT, onClick: handleCancel},
          ]}
        ></Modal>
      )}
    </>
  );
};

export default DefaultTenantSelect;
