const callApi = (endpoint: string, options: any = {}) => {
  options = {
    ...options,
    headers: {
      ...options.headers,
      Authorization: sessionStorage.getItem('authToken') && `Bearer ${sessionStorage.getItem('authToken')}`,
    },
  };

  //   Remove check after clearning tenantid addition from other places
  if (shouldAddTenantid(endpoint)) {
    const tenantid = sessionStorage.getItem('tenantid');
    /* istanbul ignore next */
    endpoint += endpoint.includes('?') ? `&tenantid=${tenantid}` : `?tenantid=${tenantid}`;
  }

  return fetch(endpoint, options)
    .then((res) => {
      return res.json();
    })
    .then((response) => {
      /* istanbul ignore next */
      if (
        response.message === 'Successful' ||
        response.success === true ||
        response.success === false ||
        response.data ||
        (typeof response === 'string' && response.includes('WarmUp - Lambda is warm!'))
      ) {
        return response;
      }
      /* istanbul ignore next */
      throw response;
    });
};

function shouldAddTenantid(endpoint: string) {
  return !endpoint.includes('tenantid=') && sessionStorage.getItem('tenantid');
}

export {callApi};
