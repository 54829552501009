import React from 'react';
import LoginContainer from './LoginContainer';
import welcomeImage from './welcome.jpg';
import styles from './ProjectWelcome.module.scss';
import {REDIRECT_LAUNCH_URL} from '../../../../services/endpoint';
// Old implementation of loggin has been commented.
/*
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { GetURLQuery } from '../../../../common/utils/queryParams';
import { fetchUserRequest, DEFAULT_USER } from '../../../projects/slices/userDetailsSlice';
*/
const ProjectWelcome = ({history}: any) => {
  /*
  const dispatch = useDispatch();
  const location = useLocation();
  const userID = GetURLQuery(location.search, 'userid') || DEFAULT_USER.data.userID;
 
  const handleLogin = () => {
      //   - Auth
      //window.location.assign(REDIRECT_LAUNCH_URL);
      sessionStorage.setItem('aunthenticatedUser', 'true');
      userID && dispatch(fetchUserRequest(userID));
      history.push('/');
  };
  */

  //   - WIP - complete authentication with auth token
  const handleLoginSSO = () => {
    window.location.assign(REDIRECT_LAUNCH_URL);
  };

  return (
    <div className={styles.welcomeContainer} data-testid="project-welcome-container">
      <div className={styles.imageContainer}>
        <img src={welcomeImage} alt={'welcome'} className={styles.image} />
      </div>
      <div className={styles.loginContainer}>
        <LoginContainer handleLogin={() => {}} handleLoginSSO={handleLoginSSO} />
      </div>
    </div>
  );
};

export default ProjectWelcome;
