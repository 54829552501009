import React, {useState, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Modal from 'apollo-react/components/Modal';
import {CONSTANTS} from '../../constants';
import SubmitFeedbackForm from './SubmitFeedbackForm';
import Loader from 'apollo-react/components/Loader';

import './styles.scss';
import {submitFeedback, resetSubmitFeedback} from '../../../features/projects/slices/SubmitFeedbackSlice';
import {FormValues} from './Utils';
import {getActiveuser} from '../../../features/projects/selectors/userDetailsSelector';
import {submitFeedbackSelector} from '../../../features/projects/selectors/SubmitFeedbackSelector';
import {openBanner} from '../../../features/projects/slices/BannerSlice';
import UserMessageBanner from '../UserMessageBanner';

type SubmitFeedbackProps = {
  open: boolean;
  onOpenClose: Function;
};

function SubmitFeedback({open, onOpenClose}: SubmitFeedbackProps) {
  const [showConfimation, setShowConfimation] = useState(false);
  const [formDirty, setFormDirty] = useState(false);
  const {tenantid} = useSelector(getActiveuser);
  const {feedbackId, loading: isLoading, error: submitFeedbackError} = useSelector(submitFeedbackSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetSubmitFeedback());
  }, [dispatch, open]);

  useEffect(() => {
    if (feedbackId || submitFeedbackError.length > 0) {
      onOpenClose(false);
      dispatch(
        openBanner({
          message: feedbackId
            ? CONSTANTS.INFORMATIONAL_MESSAGES.SUBMIT_FEEDBACK_SUCCESS_MESSAGE
            : CONSTANTS.INFORMATIONAL_MESSAGES.SUBMIT_FEEDBACK_ERROR_MESSAGE,
          bannerType: submitFeedbackError.length > 0 ? 'error' : 'success',
          autoClose: true,
        }),
      );
    }
  }, [feedbackId, submitFeedbackError, onOpenClose, dispatch]);

  const closeModal = () => {
    if (formDirty) {
      setShowConfimation(true);
    } else {
      onOpenClose(false);
    }
  };
  const closeConfimation = () => {
    setShowConfimation(false);
  };

  const handleConfirmClick = () => {
    closeConfimation();
    onOpenClose(false);
  };
  /* istanbul ignore next */
  const handleFormSubmit = (values: FormValues) => {
    const {subject, message, attachment, emailMeCopy} = values;
    dispatch(
      submitFeedback({
        subject,
        message,
        attachment,
        emailMeCopy,
        tenantid,
      }),
    );
  };

  const handleFormDirty = useCallback((dirty: boolean) => {
    setFormDirty(dirty);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader classes={{loaderOverlay: 'loaderRoot'}} />
      ) : (
        open && (
          <Modal
            className="submit-feedback"
            open={open}
            onClose={closeModal}
            title={CONSTANTS.LABELS.SUBMIT_FEEDBACK}
          >
            <SubmitFeedbackForm
              handleFormSubmit={handleFormSubmit}
              closeModal={closeModal}
              handleFormDirty={handleFormDirty}
            />
          </Modal>
        )
      )}
      {showConfimation && (
        <Modal
          className="confirmationModal"
          open={showConfimation}
          onClose={closeConfimation}
          title={CONSTANTS.LABELS.CANCEL}
          message={CONSTANTS.INFORMATIONAL_MESSAGES.SUBMIT_FEEDBACK_CANCEL}
          buttonProps={[
            {label: CONSTANTS.LABELS.GO_BACK},
            {label: CONSTANTS.LABELS.CONFIRM, onClick: handleConfirmClick},
          ]}
        ></Modal>
      )}
      <UserMessageBanner />
    </>
  );
}

export default SubmitFeedback;
