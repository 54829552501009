import {put, takeEvery, call} from 'redux-saga/effects';
import {logOutRequest, logOutSuccess, logOutFailure} from '../slices';
import {removeSessionStorage} from '../../../common/utils/useSessionStorage';
import history from '../../../common/utils/history';

export function* watchUserAuthentication() {
  yield takeEvery(logOutRequest, userLogOut);
}

export function* userLogOut({payload = ''}: any) {
  try {
    yield call(removeSessionStorage);
    yield put(logOutSuccess());
    history.push({pathname: '/logout', state: payload});
  } catch (error) {
    yield put(logOutFailure('Log Out Failed'));
  }
}
