import {put, takeEvery, call} from 'redux-saga/effects';
import {
  fetchRequest,
  fetchFailure,
  fetchSuccess,
  getWorkspaceFieldData as getWorkspaceFieldDataAction,
  updateWorkspaceFieldData,
  addApplication as addApplicationAction,
  updateApplication as updateApplicationAction,
  updateAppIntegSuccess,
  updateAppIntegError,
  addAppSuccess,
  addAppError,
  updateAppIntegStatus as updateAppIntegStatusAction,
  updateAppIntegStatusSuccess,
  updateAppIntegStatusError,
  regenerateAppToken as regenerateAppTokenAction,
  regenerateAppTokenSuccess,
  regenerateAppTokenError,
} from '../slices/IntegrationManagementSlice';
import {ENDPOINTS} from '../../../services/endpoint';
import {callApi} from '../../../services/api';
import {PayloadAction} from '@reduxjs/toolkit';

export default function* watchIntegrationManagementSaga() {
  yield takeEvery(fetchRequest, getIntegrationManagementList);
  yield takeEvery(getWorkspaceFieldDataAction, getWorkspaceList);
  yield takeEvery(addApplicationAction, addApplication);
  yield takeEvery(updateApplicationAction, updateApplication);
  yield takeEvery(updateAppIntegStatusAction, updateAppIntegStatus);
  yield takeEvery(regenerateAppTokenAction, regenerateAppToken);
}

export function* getIntegrationManagementList(
  action: PayloadAction<{
    page: string;
    limit: number;
    userid: string;
    sortedColumn: string;
    sortOrder: string;
    filters: string;
  }>,
) {
  try {
    const {page, limit, sortedColumn, sortOrder, filters} = action.payload;

    let url = `${ENDPOINTS.INTEGRATION_MANAGEMENT_INFO}?page=${page}&limit=${limit}&sortby=${sortedColumn}&order=${sortOrder}`;
    if (filters) {
      url = `${url}&${filters}`;
    }

    let result = yield call(callApi, url);
    yield put(fetchSuccess(result));
  } catch (error) {
    console.warn(error?.error);
    yield put(fetchFailure(JSON.stringify(error?.error)));
  }
}
export function* getWorkspaceList(action: any) {
  const url = `${ENDPOINTS.GET_ALL_WORKSPACES}`;
  const {data} = yield call(callApi, url);
  if (data) {
    yield put(updateWorkspaceFieldData(data));
  }
}

export function* addApplication({payload}: any) {
  try {
    const {userid, applicationName, description, workspace, reqEmail, startDate, endDate, comment} = payload;
    const url = `${ENDPOINTS.ADD_APPLICATION}?userid=${userid}`;
    const body = {
      applicationName,
      description,
      workspace,
      reqEmail,
      startDate,
      endDate,
      comment,
    };
    const data = yield call(callApi, url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(body),
    });
    if (data) {
      yield put(addAppSuccess(data));
    }
  } catch (error) {
    yield put(addAppError(JSON.stringify(error?.error)));
  }
}

export function* updateApplication({payload}: any) {
  try {
    const {userid, appid, applicationName, description, workspace, reqEmail, startDate, endDate, comment} =
      payload;
    const url = `${ENDPOINTS.UPDATE_APPLICATION}?userid=${userid}`;
    const body = {
      appid,
      applicationName,
      description,
      workspace,
      reqEmail,
      startDate,
      endDate,
      comment,
    };
    const data = yield call(callApi, url, {
      method: 'PUT',
      mode: 'cors',
      body: JSON.stringify(body),
    });
    if (data) {
      yield put(updateAppIntegSuccess(data));
    }
  } catch (error) {
    yield put(updateAppIntegError(JSON.stringify(error?.error)));
  }
}

export function* updateAppIntegStatus({payload}: any) {
  try {
    const {appid, action} = payload;
    const url = `${ENDPOINTS.UPDATE_APPLICATION_STATUS}?appid=${appid}&action=${action}`;
    const data = yield call(callApi, url, {
      method: 'PUT',
      mode: 'cors',
    });

    if (data) {
      yield put(updateAppIntegStatusSuccess(data));
    }
  } catch (error) {
    yield put(updateAppIntegStatusError(JSON.stringify(error?.error)));
  }
}

export function* regenerateAppToken({payload}: any) {
  try {
    const {userid, appid} = payload;
    const url = `${ENDPOINTS.REGENERATE_APPLICATION_TOKEN}?userid=${userid}&appid=${appid}`;
    const data = yield call(callApi, url, {
      method: 'PUT',
      mode: 'cors',
    });

    if (data) {
      yield put(regenerateAppTokenSuccess(data));
    }
  } catch (error) {
    yield put(regenerateAppTokenError(JSON.stringify(error?.error)));
  }
}
