import {Location} from 'history';
import React, {useEffect, useState} from 'react';
import {Prompt} from 'react-router-dom';
import Modal from 'apollo-react/components/Modal';
import {CONSTANTS} from '../../../common/constants';
import {useSelector} from 'react-redux';
import {getSideNavigationBladeState} from '../../../features/projects/selectors/projectDetailSelectors';
import moment from 'moment';

interface Props {
  when?: boolean | undefined;
  isSaveApplicable?: boolean | undefined;
  sessionId?: string | undefined;
  isLaunch?: boolean | undefined;
  isDraft?: boolean | undefined;
  navigate: (path: string) => void;
  cloneOrEdit?: string;
  isSaveDone?: boolean;
  defaultShow?: boolean;
  shouldBlockNavigation?: (location: Location) => boolean;
  callback: Function;
}

let beforeUnloadTime: any;
export const getBeforeUnloadTime = () => {
  return beforeUnloadTime;
};
export const setBeforeUnloadTime = (time: any) => {
  beforeUnloadTime = null;
};

const RouteLeavingGuard = ({
  when,
  isSaveApplicable,
  isDraft,
  navigate,
  cloneOrEdit,
  isSaveDone,
  defaultShow = false,
  shouldBlockNavigation,
  callback,
}: Props) => {
  const [modalVisible, setModalVisible] = useState(defaultShow);
  const [navigateAfterSave, setNavigateAfterSave] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const isSideNavigationBladeOpened = useSelector(getSideNavigationBladeState);
  /* istanbul ignore next */
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname + lastLocation.search);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  // Show browser default popup in case of unsaved changes during the page refresh.
  // This default prompt cannot be customized due to security reasons.
  /* istanbul ignore next */
  useEffect(() => {
    const blockBackButton = (e: any) => {
      e.preventDefault();
      window.history.pushState(null, document.title, window.location.href);
    };

    if (when && !isSideNavigationBladeOpened && !isSaveDone) {
      window.onbeforeunload = () => {
        beforeUnloadTime = moment();
        return true;
      };
      window.addEventListener('popstate', blockBackButton);
    } else {
      window.onbeforeunload = null;
    }

    return () => {
      window.onbeforeunload = null;
      window.removeEventListener('popstate', blockBackButton);
    };
  }, [when, isSaveDone, isSideNavigationBladeOpened]);

  useEffect(() => {
    if (isSaveDone && navigateAfterSave) {
      setConfirmedNavigation(true); // Navigate after we get save session response
    }
  }, [isSaveDone, navigateAfterSave]);

  const closeModal = () => {
    setModalVisible(false);
  };
  /* istanbul ignore next */
  const handleBlockedNavigation = (nextLocation: Location, action: any) => {
    if (when && action === 'POP') {
      // when Back button is clicked, show default browser prompt.
      // Note: This event occurs after url change happens. Hence, we can't stop url changing in the browser.
      window.history.pushState(null, document.title, window.location.href);
      setLastLocation(nextLocation);
      return 'Changes you made may not be saved.';
    } else if (!confirmedNavigation && shouldBlockNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  const handleSaveButtonClick = () => {
    setModalVisible(false);
    callback();
    setNavigateAfterSave(true);
  };

  const getModalButtons = () => {
    return [
      {
        label: isDraft && isSaveApplicable ? 'Leave without saving' : 'Leave',
        onClick: handleConfirmNavigationClick,
      },
      {label: 'Cancel', onClick: closeModal},
      ...(isDraft && isSaveApplicable ? [{label: 'Save', onClick: handleSaveButtonClick}] : []),
    ];
  };

  const getPromptMessage = () => {
    return isSaveApplicable
      ? isDraft
        ? CONSTANTS.INFORMATIONAL_MESSAGES.NOT_SAVED
        : CONSTANTS.INFORMATIONAL_MESSAGES.FINAL_CONFIRM_LEAVE
      : cloneOrEdit
      ? CONSTANTS.INFORMATIONAL_MESSAGES.FINAL_CONFIRM_LEAVE
      : CONSTANTS.INFORMATIONAL_MESSAGES.CONFIRM_LEAVE;
  };

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Modal
        open={modalVisible}
        data-testid="promptModal"
        id="warning"
        variant="warning"
        onClose={closeModal}
        title="Warning"
        message={getPromptMessage()}
        buttonProps={getModalButtons()}
      />
    </>
  );
};
export default RouteLeavingGuard;
