import { all, fork } from 'redux-saga/effects';
import {
  watchGetProtocols,
  watchGetSponsors,
  watchGetProjectFieldsData,
  watchTherapeuticAreas,
  watchOtherTherapeuticAreas,
  watchObjectives,
  watchPhases,
  watchCreateProject,
  watchUpdateProject
} from '../features/projects/sagas';
import { 
  watchGetProjectList,
  watchGetProjectDesigners,
  watchGetProjectOwner,watchGetTemplateProject } from '../features/projects/sagas/projectsList';
import {
  watchGetProjectDetail,
  watchGetAllUsers,
  watchAddProjectMember,
  watchGetAllProjectMembers,
  watchDeleteProjectMember,
  watchGetProjectResults,
} from '../features/projects/sagas/projectDetail';
import designOptionsSaga from '../features/projects/sagas/designOptions';
import setUserDetailsSaga from '../features/projects/sagas/loadUserDetails';
import trialParamSaga from '../features/projects/sagas/trialParam';
import {
  watchGetCurvesList,
  watchGetProjectCurvesList,
} from '../features/projects/sagas/ToxicityCurves';
import { watchLaunchSimulation } from '../features/projects/sagas/LaunchSimulation';
import { watchSessionResult } from '../features/projects/sagas/sessionResult';
import watchCommentSaga from '../features/projects/sagas/projectComments';
import userManagementSaga from '../features/userManagement/sagas/userManagement';
import tenantManagementSaga from '../features/tenantManagement/sagas/tenantManagement';
import watchGetSessionData from '../features/projects/sagas/sessionData';
import { watchAddUser } from '../features/userManagement/sagas/AddUser';
import { watchRedirectAuthentication } from '../features/loginPage/sagas/RedirectAuthentication';
import watchCustomDesignSagas from '../features/projects/sagas/customDesignSags';
import watchGenerateGraphSagas from '../features/projects/sagas/customDesign/generateGraph';
import watchCustomDesignResultsSagas from '../features/projects/sagas/customDesign/results';
import { watchAddTenant } from '../features/tenantManagement/sagas/AddTenant';
import { watchDocumentsSaga } from '../features/projects/sagas/documentSaga';
import watchGroupSequentialDesignSagas from '../features/projects/sagas/groupSequentialDesign';
import { watchFlowChartSaga } from '../features/projects/sagas/decisionTableSaga'
import watchDefaultTenantSelect from '../features/loginPage/sagas/DefaultTenantSelect';
import watchUserAuthenticationSagas from '../features/loginPage/sagas';
import { watchSubmitFeedback as watchSubmitFeedbackSaga } from '../features/projects/sagas/SubmitFeedback';
import watchIntegrationManagementSaga from '../features/integrationManagement/sagas/integrationManagement';
import { watchTrainingVideos } from '../features/projects/sagas/trainingVideo';

export default function* root() {
  yield all([
    fork(watchGetSponsors),
    fork(watchGetProjectFieldsData),
    fork(watchGetProtocols),
    fork(watchTherapeuticAreas),
    fork(watchOtherTherapeuticAreas),
    fork(watchObjectives),
    fork(watchPhases),
    fork(watchCreateProject),
    fork(watchUpdateProject),
    fork(watchGetProjectList),
    fork(watchGetProjectDesigners),
    fork(watchGetProjectOwner),
    fork(watchGetProjectDetail),
    fork(designOptionsSaga),
    fork(setUserDetailsSaga),
    fork(trialParamSaga),
    fork(watchGetAllUsers),
    fork(watchAddProjectMember),
    fork(watchGetAllProjectMembers),
    fork(watchDeleteProjectMember),
    fork(watchGetCurvesList),
    fork(watchLaunchSimulation),
    fork(watchGetProjectResults),
    fork(watchSessionResult),
    fork(watchCommentSaga),
    fork(userManagementSaga),
    fork(tenantManagementSaga),
    fork(watchGetProjectCurvesList),
    fork(watchGetSessionData),
    fork(watchAddUser),
    fork(watchAddTenant),
    fork(watchRedirectAuthentication),
    fork(watchCustomDesignSagas),
    fork(watchGenerateGraphSagas),
    fork(watchCustomDesignResultsSagas),
    fork(watchDocumentsSaga),
    fork(watchGroupSequentialDesignSagas),
    fork(watchFlowChartSaga),
    fork(watchDefaultTenantSelect),
    fork(watchUserAuthenticationSagas),
    fork(watchSubmitFeedbackSaga),
    fork(watchIntegrationManagementSaga),
    fork(watchTrainingVideos),
    fork(watchGetTemplateProject)
  ]);
}
