export const INFO_TEXT_DESC = {
  ACCORDION:'Software calculates accrual in days and assumes closed sites do not reopen',
  GROUP:'Based on the Total # Groups selected the corresponding groups shall be displayed',
  AVGLOTSITESTAYSOPENASD:'A large value (e.g., 10,000) indicates that the sites do not close during the trial. A small value indicates that the sites may close.'
}
export const medianRecruitmentRates = {
  formKey:'medianRecruitmentRates'
}
export const averageSiteDuration = {
  formKey:'averageSiteDuration'
}

export const GSDesignTypes = {
  GROUP_SEQUENTIAL: 'Group Sequential',
  FIXED_DESIGN: 'Fixed Design'
};

export const mockData = [
  {
    group: [
      {
        id: 'AcademicGroup',
        name: 'Academic Group.',
      },
    ],
    site: '3',
  },
  {
    group: [
      {
        id: 'NonAcademicGroup',
        name: 'Non-Academic Group.',
      },
    ],
    site: '5',
  },
  {
    group: [
      {
        id: 'AcademicGroup',
        name: 'Academic Group.',
      },
    ],
    site: '5',
  },
  {
    group: [
      {
        id: 'AcademicGroup',
        name: 'Academic Group.',
      },
    ],
    site: '5',
  },
  {
    group: [
      {
        id: 'AcademicGroup',
        name: 'Academic Group.',
      },
    ],
    site: '5',
  },
];