import React, {useState} from 'react';
import moment from 'moment';
import IconButton from 'apollo-react/components/IconButton';
import Edit from '@material-ui/icons/MoreVert';
import styles from './Table.module.scss';
import {Link} from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {selectProjectDeisgners} from '../../selectors/ProjectHomeSelectors';
import {useSelector} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Typography from 'apollo-react/components/Typography';
import {resetSessionStorage} from '../../../../common/utils';

export const DateCell = ({row}: any) => {
  return <span>{moment(row.createdat).format('DD-MMM-YYYY').toUpperCase()}</span>;
};
/* istanbul ignore next */
export const ActionCell = ({row}: any) => {
  return (
    <div style={{width: 92}}>
      <IconButton size="small" data-id={row.id}>
        <Edit />
      </IconButton>
    </div>
  );
};
/* istanbul ignore next */
export const ProjectCell = (props: any) => {
  return (
    <div className={styles.projectNameCell}>
      <Link
        to={`/projects/detail/${props.row.projectid}`}
        style={{textDecoration: 'none'}}
        onClick={() => {
          resetSessionStorage();
          sessionStorage.setItem('objID', props.row.objectId);
        }}
      >
        {props.row.projectname}
      </Link>
    </div>
  );
};

export const SponsorCell = ({row}: any) => {
  return <span>{row.sponsor || 'None'}</span>;
};
export const therapeuticAreaCell = ({row}: any) => {
  return <span>{row.therapeticarea || 'None'}</span>;
};

export function OwnerCell({row}: any) {
  const owners = useSelector(selectProjectDeisgners);
  const [selectedOwner, handleselectedOwner] = useState<any>({
    label: row.owner,
    value: row.owner,
  });
  //   Fix Autocomplete "Material-UI: The value provided to Autocomplete is invalid." which occurs as we are using Objects.
  return row.isSelected ? (
    <div style={{maxWidth: 400}}>
      <Autocomplete
        fullWidth
        multiple={false}
        value={selectedOwner as string}
        options={owners as Array<any>}
        getOptionLabel={(option: any) => option.label}
        // getOptionSelected={(option, owner) => option.name === owner.value}
        filterSelectedOptions
        renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Start typing..." />}
        closeIcon=""
        clearOnBlur
        clearOnEscape
        onChange={(event: any, owner: any) => {
          if (owner) {
            handleselectedOwner({...owner});
            row.onProjectOwnerSelect({...owner});
          }
        }}
        renderTags={(value: string[], getTagProps) =>
          value.map((option: any, index: number) => (
            <Typography variant="outlined" label={option['value']} {...getTagProps({index})} />
          ))
        }
        classes={{
          endAdornment: 'actionSectionPJ',
          paper: 'autoCompletePaperPJ',
          popper: 'autoCompletePoperPJ',
          inputRoot: 'autoCompleteInputRootPJ',
          input: 'autoCompleteInputPJ',
          tag: 'autoCompleteChipPJ',
        }}
        data-testid="ownerDropdown"
      />
    </div>
  ) : (
    <span>{row.owner}</span>
  );
}
