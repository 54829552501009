import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UserManagementListState} from '../types/UserManagementListType';
import {
  isSystemError,
  getSelectedUser,
  ACTION_USER_ACTIVATION,
  ACTION_USER_ROLE_CHANGE_ROLE,
} from '../utils/userManagmentTableCommon';

export const initialState: UserManagementListState = {
  data: [],
  isLoading: true,
  isLoadingOnAction: false,
  error: '',
  totalRecords: 0,
  currentPage: 1,
  showErrorModal: {
    isActive: false,
    message: '',
  },
  showBanner: {
    isActive: false,
    message: '',
  },
  updateUserRole: {
    error: '',
  },
  updateUserStatus: {
    error: '',
  },
  isDataNeedToReload: false,
  selectedUserDetails: null,
};

export const userManagementListSlice = createSlice({
  name: 'userManagementlist',
  initialState: initialState as UserManagementListState,
  reducers: {
    fetchRequest: (state: UserManagementListState, action) => {
      state.isLoading = true;
    },
    fetchSuccess: (state: UserManagementListState, action: PayloadAction<UserManagementListState>) => {
      const {
        payload: {data, totalRecords, currentPage},
      } = action;
      state.data = data;
      state.totalRecords = totalRecords;
      state.currentPage = currentPage;
      state.isLoading = false;
    },
    updateRoleRequest: (state: UserManagementListState, action) => {
      const {userId} = action.payload;
      state.selectedUserDetails = getSelectedUser(state.data, userId);
      state.isLoadingOnAction = true;
    },
    updateUserStatusRequest: (state: UserManagementListState, action) => {
      const {userId} = action.payload;
      state.selectedUserDetails = getSelectedUser(state.data, userId);
      state.isLoadingOnAction = true;
    },
    updateRequestFailure: (state: UserManagementListState, action) => {
      const {type = '', message = '', requestType} = action.payload;
      const showErrorInModal = isSystemError(type);
      /* istanbul ignore next */
      if (showErrorInModal) {
        state.showErrorModal.isActive = showErrorInModal;
        state.showErrorModal.message = message ? message : '';
      }
      if (requestType === ACTION_USER_ROLE_CHANGE_ROLE) {
        state.updateUserRole.error = message;
      } /* istanbul ignore next */ else if (requestType === ACTION_USER_ACTIVATION) {
        state.updateUserStatus.error = message;
      }
      state.isLoadingOnAction = false;
    },
    updateRequestSuccess: (state: UserManagementListState, action) => {
      const {requestType} = action.payload;
      if (requestType === ACTION_USER_ROLE_CHANGE_ROLE) {
        state.showBanner.message = `Success${ACTION_USER_ROLE_CHANGE_ROLE}`;
      } /* istanbul ignore next */ else if (requestType === ACTION_USER_ACTIVATION) {
        state.showBanner.message = `Success${ACTION_USER_ACTIVATION}`;
      }
      state.showBanner.isActive = true;
      state.isLoadingOnAction = false;
      state.isDataNeedToReload = true;
    },
    fetchFailure: (state: UserManagementListState, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setDataReload: (state: UserManagementListState, action) => {
      state.isDataNeedToReload = action.payload;
    },
    closeErrorModal: (state: UserManagementListState) => {
      state.showErrorModal.isActive = false;
      state.showErrorModal.message = '';
      state.selectedUserDetails = null;
    },
    closeBanner: (state: UserManagementListState) => {
      state.showBanner.isActive = false;
      state.showBanner.message = '';
      state.selectedUserDetails = null;
    },
  },
});

export const {
  fetchRequest,
  fetchFailure,
  fetchSuccess,
  updateRoleRequest,
  setDataReload,
  closeErrorModal,
  closeBanner,
  updateUserStatusRequest,
  updateRequestFailure,
  updateRequestSuccess,
} = userManagementListSlice.actions;
export default userManagementListSlice.reducer;
