import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SimulationPayloadType = {
  message?: string;
  data: {
    sessionID: number | string | null;
    sessionName: string;
  };
  isNewSession?: boolean;
};
export interface LaunchSimulationType {
  data: SimulationPayloadType;
  loading: boolean;
  error: string;
}

const initialState: LaunchSimulationType = {
  data: {
    data: {
      sessionID: null,
      sessionName: '',
    },
    isNewSession: false,
  },
  loading: false,
  error: '',
};

const launchSimulationSlice = createSlice({
  name: 'launchSimulation',
  initialState: initialState as LaunchSimulationType,
  reducers: {
    launchSimulation: (state: LaunchSimulationType, action) => {
      console.log("first call")
      state.loading = true;
    },
    updateLaunchSimulation: (
      state: LaunchSimulationType,
      action: PayloadAction<SimulationPayloadType>
    ) => {
      state.data = action.payload;
      state.loading = false;
      state.error = '';
    },
    simulationError: (state, action: PayloadAction<any>) => {
      state.data = { data: { sessionID: null, sessionName: '' } };
      state.loading = false;
      state.error = action.payload;
    },
    resetlaunchSimulationData: (state) => {
      state.data = initialState.data;
      state.error = '';
      state.loading = false;
    },
  },
});

export const {
  launchSimulation,
  updateLaunchSimulation,
  simulationError,
  resetlaunchSimulationData,
} = launchSimulationSlice.actions;

export default launchSimulationSlice.reducer;
