import {Form, FormikProps, withFormik, useFormikContext} from 'formik';
import React, {useState, useEffect, useMemo} from 'react';
import {isEmpty} from 'lodash';
import {CONSTANTS} from '../../constants';
import {FormikCheckbox, FormikTextField} from '../formik-controls/FormikComponents';
import Modal from 'apollo-react/components/Modal';
import Button from 'apollo-react/components/Button';
import FileUpload from 'apollo-react/components/FileUpload';
import {FormValues, CompProps, SubmitFeedbackFormSchema, FileType} from './Utils';

import './styles.scss';
import {getFileSizeInMB} from '../../../features/projects/utils/documentUtil';

const allowedTypes = ['JPG', 'jpg', 'JPEG', 'jpeg', 'PNG', 'png'];
export const maxSize = getFileSizeInMB(10); // 10 MB max file size

function SubmitFeedbackForm({
  isSubmitting,
  dirty,
  handleFormDirty,
  errors,
  touched,
  closeModal,
}: CompProps & FormikProps<FormValues>) {
  useEffect(() => {
    handleFormDirty(dirty);
  }, [dirty, handleFormDirty]);

  const [selectedFiles, setSelectedFiles] = useState<FileType[]>();
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const {setFieldValue}: {setFieldValue: Function} = useFormikContext();

  const onUpload = (files: FileType[]) => {
    const file = files[0];
    const [type, ...nameParts] = file.name.split('.').reverse();
    if (!allowedTypes.includes(type)) {
      setErrorMsg(CONSTANTS.VALIDATION_MESSAGES.SUBMIT_FEEDBACK_ALLOWED_FILE_TYPES);
      return setShowError(true);
    } else if (file.size > maxSize) {
      setErrorMsg(CONSTANTS.VALIDATION_MESSAGES.SUBMIT_FEEDBACK_ALLOWED_FILE_SIZE);
      return setShowError(true);
    } else if (nameParts.join('').length > 65) {
      setErrorMsg(CONSTANTS.VALIDATION_MESSAGES.SUBMIT_FEEDBACK_ALLOWED_FILE_NAME_LENGTH);
      return setShowError(true);
    }

    setSelectedFiles([file]);
    setFieldValue('attachment', file);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if ((event.charCode || event.keyCode) === 13) {
      event.preventDefault();
    }
  };

  const isFormInValid = useMemo(() => {
    if (isEmpty(touched)) {
      return true;
    } else if (!isEmpty(touched) && !isEmpty(errors)) {
      return true;
    }

    return false;
  }, [touched, errors]);

  const onErrorClose = () => {
    setErrorMsg('');
    setShowError(false);
  };

  return (
    <>
      <Form noValidate className="submitFeedbackForm" data-testid="submitFeedbackForm">
        <FormikTextField
          fullWidth
          name="subject"
          label={CONSTANTS.LABELS.SUBJECT}
          placeholder="Start typing..."
          required
          helperText=""
          data-testid="subject"
          onKeyDown={handleKeyDown}
        />
        <FormikTextField
          fullWidth
          name="message"
          label={CONSTANTS.LABELS.MESSAGE}
          placeholder="Start typing..."
          required
          helperText=""
          data-testid="message"
          sizeAdjustable
          minHeight={200}
        />
        <br />
        <br />
        <FileUpload
          name="attachment"
          fullWidth
          data-testid="attachment"
          value={selectedFiles}
          onUpload={onUpload}
          onFileDelete={() => setSelectedFiles([])}
          label={CONSTANTS.LABELS.SUBMIT_FEEDBACK_UPLOAD}
          maxItems={1}
        />
        <div className="submit-feedback-actions">
          <div className="email-me-copy">
            <FormikCheckbox name="emailMeCopy" label="Email me a copy" />
          </div>
          <div className="action-buttons">
            <Button data-testid="close" variant="tertiary" type="button" onClick={closeModal}>
              {CONSTANTS.LABELS.CANCEL}
            </Button>
            <Button
              data-testid="confirm"
              variant="primary"
              type="submit"
              disabled={isFormInValid || isSubmitting}
            >
              {CONSTANTS.LABELS.SUBMIT}
            </Button>
          </div>
        </div>
      </Form>
      <Modal
        className="fileError"
        open={showError}
        data-testid="errorModal"
        variant="error"
        onClose={onErrorClose}
        title={CONSTANTS.LABELS.ERROR}
        message={errorMsg}
        buttonProps={[{label: CONSTANTS.LABELS.OK}]}
      ></Modal>
    </>
  );
}

const initialValues: FormValues = {
  subject: '',
  message: '',
  attachment: undefined,
  emailMeCopy: true,
};
/* istanbul ignore next */
const handleSubmit = (values: any, {props}: any) => {
  props.handleFormSubmit(values);
};

const enhancedForm = withFormik<CompProps, FormValues>({
  validationSchema: SubmitFeedbackFormSchema(),
  validateOnBlur: true,
  mapPropsToValues: () => initialValues,
  handleSubmit,
})(SubmitFeedbackForm);

export default enhancedForm;
