import {put, takeEvery, call} from 'redux-saga/effects';
import {
  fetchSuccess,
  fetchFailure,
  fetchRequest,
  getProjectDesigners as getProjectDesignersAction,
  updateProjectDesigners as updateProjectDesignersAction,
  updateProjectOwner as upadateProjectOwnerAction,
  updateProjectOwnerSuccess,
  updateProjectOwnerFailure,
  updateTemplateProject as updateTemplateProjectAction,
  updateTemplateProjectSuccess,
  updateTemplateProjectFailure,
} from '../slices/TableSlice';
import {ENDPOINTS} from '../../../services/endpoint';
import {callApi} from '../../../services/api';
import {PayloadAction} from '@reduxjs/toolkit';
import {tabsType} from '../types/ProjectListType';

export function* watchGetProjectList() {
  yield takeEvery(fetchRequest, getProjectList);
}

export function* getProjectList(
  action: PayloadAction<{
    type: tabsType;
    page: string;
    limit: number;
    userid: string;
    tenantid: string;
    sortOrder: string;
    sortedColumn: string;
    filters: string;
  }>,
) {
  try {
    const {page, limit, type = 'all', userid, tenantid, sortedColumn, sortOrder, filters} = action.payload;
    let url = `${ENDPOINTS.PROJECT_LIST}?userid=${userid}&tenantid=${tenantid}&type=${type}&page=${page}&limit=${limit}&sortby=${sortedColumn}&order=${sortOrder}`;
    if (filters) {
      url = `${url}&${filters}`;
    }
    const result = yield call(callApi, url);
    yield put(fetchSuccess(result));
    // resetSessionStorage();
  } catch (error) {
    console.warn(error?.error);
    yield put(fetchFailure(error?.error));
  }
}

export function* watchGetProjectDesigners() {
  yield takeEvery(getProjectDesignersAction, getProjectDesignersSaga);
}

export function* getProjectDesignersSaga(action: PayloadAction<{tenantid: string}>) {
  const {tenantid} = action.payload;
  let url = `${ENDPOINTS.GET_ALL_TENANT_DESIGNERS}?&tenantid=${tenantid}`;
  try {
    const {data} = yield call(callApi, url);
    yield put(updateProjectDesignersAction(data));
  } catch (e) {
    console.warn(e?.error);
    yield put(updateProjectDesignersAction([]));
  }
}

export function* watchGetProjectOwner() {
  yield takeEvery(upadateProjectOwnerAction, upadateProjectOwnerSaga);
}

export function* upadateProjectOwnerSaga(
  action: PayloadAction<{
    userid: string;
    tenantid: string;
    projectid: string;
    oldownerid: string;
    newownerid: string;
    newOwner: string;
    retainoldowner: boolean;
    page: number;
    limit: string;
    tab: string;
    sortedColumn: string;
    sortOrder: string;
  }>,
) {
  let {
    userid,
    tenantid,
    projectid,
    oldownerid,
    newownerid,
    newOwner,
    retainoldowner,
    page,
    limit,
    tab,
    sortedColumn,
    sortOrder,
  } = action.payload;
  //   Check with API developer if the projectList can be sent as repsonse to UpdateProjctOwner.
  const url = `${ENDPOINTS.UPDATE_PROJECT_OWNER}?tenantid=${tenantid}`;
  try {
    const body = {
      userid: userid,
      tenantid: tenantid,
      projectid: projectid,
      oldownerid: oldownerid,
      newownerid: newownerid,
      retainoldowner: retainoldowner,
    };
    const {message} = yield call(callApi, url, {
      method: 'PUT',
      mode: 'cors',
      body: JSON.stringify(body),
    });
    yield put(updateProjectOwnerSuccess({message, projectid, newOwner}));
    yield put(fetchRequest({page: ++page, limit, tab, userid, tenantid, sortedColumn, sortOrder}));
  } catch (error) {
    console.warn(error?.error);
    yield put(updateProjectOwnerFailure(error?.error));
  }
}

//Template Project
export function* watchGetTemplateProject() {
  yield takeEvery(updateTemplateProjectAction, upadateTemplateProjectSaga);
}

export function* upadateTemplateProjectSaga(
  action: PayloadAction<{
    userid: string;
    tenantid: string;
    projectid: string;
    page: number;
    limit: string;
    sortedColumn: string;
    sortOrder: string;
  }>,
) {
  let {tenantid, projectid, page, limit, userid, sortedColumn, sortOrder} = action.payload;
  //   Check with API developer if the projectList can be sent as repsonse to UpdateProjctOwner.
  const url = `${ENDPOINTS.ADD_TEMPLATE_PROJECT}?tenantId=${tenantid}&tenantid=${tenantid}&type=templateUser`;
  try {
    const body = {
      projectId: projectid,
    };
    const {message} = yield call(callApi, url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(body),
    });
    console.log('message', message);
    yield put(updateTemplateProjectSuccess({message, projectid}));
    yield put(fetchRequest({page: ++page, limit, userid, tenantid, sortedColumn, sortOrder}));
  } catch (error) {
    console.warn(error?.error);
    yield put(updateTemplateProjectFailure(error?.error));
  }
}
