import {getSessionStorage} from './../../../../common/utils/useSessionStorage';
/* eslint-disable no-sequences */
import {put, takeEvery, call, select, delay} from 'redux-saga/effects';
import {
  fetchRequest,
  fetchSuccess,
  fetchFailure,
  updateCompute,
  autoSaveGSSession,
  updateComputeSuccess,
  updateComputeFailure,
  fetchSavedSessionRequest,
  fetchSavedSessionSuccess,
  fetchSavedSessionFailure,
  fetchSSRUpdateRequest,
  fetchSSRUpdateSuccess,
  fetchSSRUpdateFailure,
} from '../../slices/groupSequentialDesignSlice/SessionPage';
import {getActiveuser} from '../../selectors/userDetailsSelector';
import {logOutRequest} from '../../../loginPage/slices/index';
import {ENDPOINTS} from '../../../../services/endpoint';
import {callApi} from '../../../../services/api';
import {openBanner} from '../../slices/BannerSlice';
import history from '../../../../common/utils/history';
import {RootState} from '../../../../app/store';
import {OpenResultSocket} from '../sessionResult';
import {extractFields, mapDefaultValues, mergeSchemaWithFormValues} from '../../utils/gsSaga_util';
import {transformResults} from './sessionResults';

import {fetchSuccess as fetchSuccessRes} from '../../slices/groupSequentialDesignSlice/SessionResults';

export default function* watchSessionPageSagas() {
  yield takeEvery(fetchRequest, getSessionFormsData);
  yield takeEvery(updateCompute, updateComputeData);
  yield takeEvery(autoSaveGSSession, autoSaveGSSessionData);
  yield takeEvery(fetchSavedSessionRequest, getSavedSessionRequest);
  yield takeEvery(fetchSSRUpdateRequest, getUpdatedSSRValue);
}

export function* getSessionFormsData({payload}: any): any {
  try {
    const url = `${ENDPOINTS.COMMON_TRIAL_PARAMS}?objid=${2}`;
    const {data} = yield call(callApi, url);
    if (data.length) {
      // Need to remove when data is availiable from api
      const defaultValues = mapDefaultValues(data);
      const fieldsInfo = yield extractFields(data);
      yield put(fetchSuccess({...fieldsInfo, schema: data, defaultValues}));
    }
  } catch (error) {
    console.warn(error);
    yield put(fetchFailure(error?.error));
  }
}

export function* updateComputeData({payload}: any): any {
  try {
    const {
      projid,
      userid,
      status,
      tenantid,
      sessionName,
      formData,
      sessionId,
      isCompute,
      optionAction,
      resultId,
      clonedResultId,
    } = payload;
    const IsNewSession = sessionId ? false : true;
    let url = generateSaveUrl(payload);
    const isFreshSaved = status === 'Draft' && IsNewSession;

    let cloneOf;

    switch (optionAction) {
      case 'edit':
        cloneOf = clonedResultId ? {resultId: clonedResultId} : '';
        break;
      case 'clone':
        cloneOf = {resultId};
        break;
      default:
        cloneOf = '';
    }
    const { "": _, ...updatedFormData } = formData;
    const body = {
      sessionName: sessionName,
      jsonFile: {
        ...updatedFormData,
        ...(cloneOf && {cloneOf}),
      },
    };

    if (isCompute) {
      url += `&ec2=${isCompute}`;
      try {
        yield call(callApi, `${ENDPOINTS.INVOKE_R_SIMULATION_LAMBDA}?frontendPing=true`, {
          method: 'POST',
          mode: 'cors',
        });
      } catch (error) {
        yield delay(1000);
        try {
          yield call(callApi, `${ENDPOINTS.INVOKE_R_SIMULATION_LAMBDA}?frontendPing=true`, {
            method: 'POST',
            mode: 'cors',
          });
        } catch (error) {
          yield put(
            openBanner({
              message: `Sorry!! something went wrong. Please try after some time`,
              bannerType: 'error',
              autoClose: true,
            }),
          );
          yield put(updateComputeFailure(error?.error));
          return null;
        }
      }
      const {data, ...rest} = yield call(callApi, url, {
        method: IsNewSession || optionAction ? 'POST' : 'PUT',
        mode: 'cors',
        body: JSON.stringify(body),
      });

      const results = yield transformResults(data);
      if (results) {
        yield put(fetchSuccessRes({data: results, ...rest}));
        history.push(`/projects/output/${results[0].sessionId}`);
      }
    } else {
      url += `&status=${status}`;
      const data = yield call(callApi, url, {
        method: IsNewSession || optionAction ? 'POST' : 'PUT',
        mode: 'cors',
        body: JSON.stringify(body),
      });
      if (data) {
        if (status === 'Draft') {
          data.intialValues = formData;
        }
        yield put(updateComputeSuccess({data, status, IsNewSession}));

        if (status !== 'Draft') {
          yield call(
            OpenResultSocket,
            {sid: data.sessionId, projectId: projid, userid, tenantid},
            payload.dispatch,
          );
        }

        // append sessionID on url, for fresh saved session
        if (isFreshSaved && !payload?.fromPrompt) {
          history.replace({pathname: `/projects/session/${data.sessionId}`});
        }

        status !== 'Draft' && history.push(`/projects/output/${data.sessionId}`);
      }
    }
  } catch (error) {
    console.warn(error);
    yield put(updateComputeFailure(error?.error));
  }
}

export function* getUpdatedSSRValue({payload}: any): any {
  try {
    const body = {
      jsonFile: payload,
    };
    const {
      trialParameters: {endPoint},
    } = payload;
    let url;
    if (endPoint === 'DIFFBINOM') {
      url = `${ENDPOINTS.GET_UTILITY_FUNCTION_GRAPHS}?action=fetchPlannedSampleSize&endpoint=DIFFBINOM`;
    } else {
      url = `${ENDPOINTS.GET_UTILITY_FUNCTION_GRAPHS}?action=fetchPlannedSampleSize`;
    }
    const {data} = yield call(callApi, url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(body),
    });

    if (data) {
      yield put(fetchSSRUpdateSuccess({endPoint, data}));
    }
  } catch (error) {
    console.warn(error);
    yield put(fetchSSRUpdateFailure([]));
    yield put(
      openBanner({
        message: `Sorry!! something went wrong while updating`,
        bannerType: 'error',
        autoClose: true,
      }),
    );
  }
}

export function* autoSaveGSSessionData({payload}: any): any {
  try {
    const isLogoutButtonClicked: boolean = yield select(
      (state: RootState) => state.authInfo.isLogoutButtonClicked,
    );
    const {sessionName, formData, sessionId, optionAction} = payload;
    const IsNewSession = sessionId ? false : true;
    const url = generateSaveUrl(payload);

    const body = {
      sessionName: sessionName,
      jsonFile: formData,
    };

    const data = yield call(callApi, url, {
      method: IsNewSession || optionAction ? 'POST' : 'PUT',
      mode: 'cors',
      body: JSON.stringify(body),
    });

    if (data) {
      yield put(logOutRequest(isLogoutButtonClicked ? '' : 'autologout'));
    }
  } catch (error) {
    console.warn(error);
    yield put(logOutRequest(''));
  }
}

type testTypes = '1-sided' | '2-sided Asymmetric' | '2-sided Symmetric';
const newTestTypeMap: {[x in testTypes]: string} = {
  '1-sided': '1Sided',
  '2-sided Symmetric': '2SidedSymmetric',
  '2-sided Asymmetric': '2SidedAsymmetric',
};

export function* getSavedSessionRequest({payload}: any): any {
  try {
    const {tenantid, userid} = yield select(getActiveuser);
    const {projectId, sessionId, resultId} = payload;
    let {schema, defaultValues} = yield select((state: RootState) => state.groupSequentialDesign.sessionPage);
    if (schema && !schema.length) {
      const url = `${ENDPOINTS.COMMON_TRIAL_PARAMS}?objid=${2}`;
      const {data} = yield call(callApi, url);
      if (data.length) {
        schema = data;
        defaultValues = mapDefaultValues(data);
      } else {
        throw Error('Error');
      }
    }
    const url = `${
      ENDPOINTS.PROJECT_SESSION_DATA
    }?projectid=${projectId}&userid=${userid}&tenantid=${tenantid}&sessionid=${sessionId}${
      resultId ? '&resultid=' + resultId : ''
    }`;
    const {data, ...rest} = yield call(callApi, url);

    if (data && Object.keys(data).length) {
      // replace with newtestType value to Id
      if (data?.trialParameters && data?.trialParameters?.testType) {
        const testType: testTypes = data?.trialParameters?.testType;
        data.trialParameters['testType'] = newTestTypeMap[testType] ? newTestTypeMap[testType] : testType;
      }
      const mapData = yield mergeSchemaWithFormValues(schema, data);
      if (data?.trialParameters && !data?.trialParameters?.endPoint) {
        data.trialParameters['endPoint'] = 'TTE';
      }
      const enableLock = data.trialParameters['enableLock'] || false;
      const coefficientLock = data.trialParameters['coefficientLock'] || false;

      const fieldsInfo = yield extractFields(mapData, data);
      const {ssr = {}} = data;
      yield put(
        fetchSavedSessionSuccess({
          ...fieldsInfo,
          schema,
          ...rest,
          defaultValues,
          ssr,
          enableLock,
          coefficientLock,
        }),
      );
    }
  } catch (error) {
    console.warn(error);
    yield put(fetchSavedSessionFailure(error?.message ? error.message : error?.error));
  }
}

// Generate saveurl based on various conditions
//   Optimise it.
const generateSaveUrl = (payload: any) => {
  const {projid, userid, status, tenantid, sessionId, optionAction, resultId, isAutoSave} = payload;
  let cloneSessionDetails = getSessionStorage('cloneEditOption') || {};
  const IsNewSession = sessionId && !cloneSessionDetails?.newSessionClone ? false : true;
  let url;
  if (optionAction && !cloneSessionDetails?.newSessionClone) {
    url = `${ENDPOINTS.ADJUST_GS_INPUTS}?projid=${projid}&userid=${userid}&tenantid=${tenantid}&action=${optionAction}&sessionid=${sessionId}&resultid=${resultId}`;
  } else {
    url = IsNewSession
      ? `${ENDPOINTS.SAVE_GROUP_SEQUENTIAL_PARAMETERS}?projid=${projid}&userid=${userid}&tenantid=${tenantid}&status=${status}`
      : `${ENDPOINTS.UPDATE_GROUP_SEQUENTIAL_PARAMETERS}?projid=${projid}&userid=${userid}&tenantid=${tenantid}&status=${status}&sessionid=${sessionId}`;
  }

  url += `&status=${status}`;

  if (isAutoSave) {
    url += `&type=autosave`;
  }

  return url;
};
