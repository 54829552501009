import {put, takeEvery, call, select} from 'redux-saga/effects';
import {roundNumber, parseToPrecision} from '../../../../common/utils';
import {
  fetchRequest,
  fetchSuccess,
  fetchFailure,
  userRatingRequest,
  userRatingSuccess,
  userRatingError,
  fetchCompareRequest,
  fetchCompareSuccess,
  fetchCompareFailure,
  adjustInputsRequest,
  adjustInputsSuccess,
  adjustInputsFailure,
  getLastOptionDataRequest,
  getLastOptionDataSuccess,
  getLastOptionDataFailure,
  updateGraphRange,
  updateGraphRangeSuccess,
  updateGraphRangeFailure,
  fetchUpdateRangeSessionData,
  fetchUpdateRangeSessionDataSucess,
  fetchUpdateRangeSessionDataFailure,
} from '../../slices/groupSequentialDesignSlice/SessionResults';

import {ENDPOINTS} from '../../../../services/endpoint';
import {callApi} from '../../../../services/api';
import {getActiveuser} from '../../selectors/userDetailsSelector';
import {find, has, omit, pullAllBy, get} from 'lodash';
import {OpenResultSocket} from '../sessionResult';
import {forwardTo} from '../projectDetail';
import {openBanner} from '../../slices/BannerSlice';
import {CONSTANTS} from '../../../../common/constants';
import {getSessionStorage} from '../../../../common/utils/useSessionStorage';
import {RootState} from '../../../../app/store';
import {GS_CONSTANTS} from '../../../../common/constants/groupSequential';

type endpointType = 'DIFFMEANS' | 'TTE' | 'DIFFBINOM';
type trialDesignType = 'equivalence' | 'noninferiority' | 'superiority' | 'non-inferiority';

export default function* watchCustomDesignResultsSagas() {
  yield takeEvery(fetchRequest, getResultsData);
  yield takeEvery(userRatingRequest, userRatingDesignSaga);
  yield takeEvery(fetchCompareRequest, getCompareResults);
  yield takeEvery(adjustInputsRequest, adjustInputsSaga);
  yield takeEvery(getLastOptionDataRequest, lastOptionDataSaga);
  yield takeEvery(updateGraphRange, updateGraphRangeSaga);
  yield takeEvery(fetchUpdateRangeSessionData, getUpdateRangeSessionData);
}

export function* getResultsData({payload}: any): any {
  const {sid, projectId, projectSessionId, label} = payload;
  try {
    const {tenantid, userid} = yield select(getActiveuser);
    const resultAPIurl = `${ENDPOINTS.GET_CUSTOM_SESSION_RESULT_DATA}?sessionid=${sid}&tenantid=${tenantid}&userid=${userid}&projectid=${projectId}`;
    const {data, ...rest} = yield call(callApi, resultAPIurl);
    const results = yield transformResults(data);
    yield put(fetchSuccess({data: results, ...rest}));
    if (data) {
      const isRCodeCompleted = data.map((d: any) => d.rCodeResult).every((e: any) => e === 'Success');

      if (!isRCodeCompleted) {
        yield call(OpenResultSocket, {sid, projectId, userid, tenantid}, payload.dispatch);
      }
    }
  } catch (error) {
    console.warn(error);
    yield put(fetchFailure(error?.error));
    if (error?.isSessionDeleted) {
      yield put(
        openBanner({
          message: CONSTANTS.INFORMATIONAL_MESSAGES.SESSION_RESULT_DELETE_MESSAGE(projectSessionId, label),
          bannerType: 'warning',
          autoClose: true,
        }),
      );
      yield call(forwardTo, `/projects/detail/${projectId}`);
    }
  }
}

export function* userRatingDesignSaga({payload}: any) {
  try {
    const {tenantid, userid} = yield select(getActiveuser);
    const {resultid, sessionid, projectid, ratingvalue} = payload;
    const url = `${ENDPOINTS.POST_SESSION_OUTPUT_RATING}?userid=${userid}&tenantid=${tenantid}`;

    const body = {
      resultid,
      sessionid,
      projectid,
      ratingvalue,
    };
    const data = yield call(callApi, url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(body),
    });
    const response = {...data, ...payload};
    if (response) {
      yield put(userRatingSuccess(response));
    }
  } catch (error) {
    yield put(userRatingError(error?.error));
  }
}

export function* getCompareResults({payload}: any) {
  const {resultids, projectId} = payload;
  try {
    const {tenantid, userid} = yield select(getActiveuser);
    const url = `${ENDPOINTS.GET_CUSTOM_OUTPUT_COMPARISION_RESULTS}?resultids=${resultids}&userid=${userid}&tenantid=${tenantid}&projectid=${projectId}`;
    const {data, ...rest} = yield call(callApi, url);
    const results = yield transformResults(data);
    if (data) {
      yield put(fetchCompareSuccess({data: results, ...rest}));
    }
  } catch (error) {
    yield put(fetchCompareFailure(error?.error));
    if (error?.isSessionDeleted) {
      yield put(
        openBanner({
          message: CONSTANTS.INFORMATIONAL_MESSAGES.SESSION_COMPARE_RESULTS_DELETE_MESSAGE,
          bannerType: 'warning',
          autoClose: true,
        }),
      );
      yield call(forwardTo, `/projects/detail/${projectId}`);
    }
  }
}

export function* adjustInputsSaga({payload}: any) {
  try {
    const {tenantid, userid} = yield select(getActiveuser);
    const {trialParameters, trialDurationParameters, sid, projectId, sessionName} = payload;
    const url = `${ENDPOINTS.ADJUST_GS_INPUTS}?projid=${projectId}&userid=${userid}&tenantid=${tenantid}&sessionid=${sid}`;

    const body = {
      jsonFile: {
        sessionName,
        trialDurationParameters: trialDurationParameters,
        trialParameters: trialParameters,
      },
    };
    const data = yield call(callApi, url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(body),
    });
    if (data) {
      yield put(adjustInputsSuccess(data));
    }
  } catch (error) {
    yield put(adjustInputsFailure(error?.error));
  }
}

export function* lastOptionDataSaga({payload}: any): any {
  try {
    const {tenantid, userid} = yield select(getActiveuser);
    const {projectId, sid} = payload;
    const url = `${ENDPOINTS.PROJECT_SESSION_DATA}?projectid=${projectId}&userid=${userid}&tenantid=${tenantid}&sessionid=${sid}&getLastOption=true`;
    const {data} = yield call(callApi, url);
    if (data) {
      yield put(getLastOptionDataSuccess(data));
    }
  } catch (error) {
    console.warn(error);
    yield put(getLastOptionDataFailure(error?.error));
  }
}

export function* updateGraphRangeSaga({payload}: any) {
  try {
    const {tenantid, userid} = yield select(getActiveuser);
    const {projectId} = getSessionStorage('projectDetail');
    const {graphLimitInputs, sid, resultId, isCompare, history} = payload;
    const {updateRangeSessionData} = yield select(
      (state: RootState) => state.groupSequentialDesign.sessionResults,
    );
    const url = `${ENDPOINTS.ADJUST_GS_INPUTS}?projid=${projectId}&userid=${userid}&tenantid=${tenantid}&sessionid=${sid}&resultid=${resultId}&status=Completed&ec2=true&action=edit`;
    const body = {
      jsonFile: {graphLimitInputs, ...updateRangeSessionData},
    };
    const {data} = yield call(callApi, url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(body),
    });
    const results = yield transformResults(data);
    if (isCompare) {
      history.go(0);
    } else if (data) {
      yield put(updateGraphRangeSuccess({data: results}));
    }
  } catch (error) {
    console.log(error);
    yield put(updateGraphRangeFailure(error?.error));
  }
}

export function* getUpdateRangeSessionData({payload}: any) {
  try {
    const {tenantid, userid} = yield select(getActiveuser);
    const {projectId} = getSessionStorage('projectDetail');
    const {sid, resultId} = payload;
    const url = `${ENDPOINTS.PROJECT_SESSION_DATA}?projectid=${projectId}&userid=${userid}&tenantid=${tenantid}&sessionid=${sid}&resultid=${resultId}`;
    const {data, sessionName} = yield call(callApi, url);
    if (data) {
      yield put(fetchUpdateRangeSessionDataSucess({data, sessionName}));
    }
  } catch (error) {
    yield put(fetchUpdateRangeSessionDataFailure(error?.error));
  }
}

export function* transformResults(payload: any): any {
  if (payload?.length <= 0) {
    return null;
  }
  return yield payload.map((result: any) => {
    const {formattedInput = [], input, output, endPoint, ...rest} = result;
    const timeUnit = formattedInput.length ? find(formattedInput, {name: 'timeUnit'}) : '';
    const adjustInputs = input.reduce((acc: any, el: any) => ({...acc, ...{[el.name]: el}}), {});
    const newInput = transformInputs(formattedInput, endPoint, adjustInputs);
    const newOutput = output ? transforRCODEResults(output, endPoint) : {};
    return {
      ...rest,
      input: newInput,
      output: newOutput,
      timeUnit: timeUnit?.value || '',
      endPoint,
      adjustInputs,
    };
  });
}

const transformInputs = (payload: any, endPoint: any, formInputs?: any) => {
  if (!payload?.length) {
    return [];
  }
  let inputs: any[] = pullAllBy(
    payload,
    [{name: 'designType'}, {name: 'testType'}, {name: 'timeUnit'}, {name: 'interimAnalysesPlanned'}],
    'name',
  );

  inputs = inputs.filter(
    (input) =>
      input.name !== 'ssrMethod' &&
      input.name !== 'typesOfTrialDesigns' &&
      input.name !== 'cumulativeAlphaSpentUpperHaybittle',
  );

  let results = inputs
    .map((el: any) => {
      el['viewType'] = 'list';
      if (
        (el.name === 'hazardRateTablePeriods' || el.name === 'accrualRateTablePeriods') &&
        Array.isArray(el.value)
      ) {
        el['viewType'] = 'table';
        el.value = el.value.map((item: any) => {
          delete item?.rowId;
          delete item?.rowIndex;
          if (item?.hazardRateDuration === '') {
            item.hazardRateDuration = CONSTANTS.LABELS.TOBE_COMPUTED;
          }
          if (item?.accrualRateDuration === '') {
            item.accrualRateDuration = CONSTANTS.LABELS.TOBE_COMPUTED;
          }
          return item;
        });

        const data = mapper(el.name, el.value);
        el.value = data;
      }
      return el;
    })
    .filter((el) => el.name !== 'calculatePowerFixedDesign');

  // adds special cases for not specified/provided by user from session Page
  if (Object.keys(formInputs).length) {
    if (formInputs?.isSspecifyExpectedDuration?.value === 'No') {
      results.push({
        name: 'trialDuration',
        label: 'Trial Duration',
        value: CONSTANTS.LABELS.TOBE_COMPUTED,
        viewType: 'list',
      });
    }

    if (formInputs?.isMinimumFollowUp?.value === 'No') {
      results.push({
        name: 'minimumFollowUpValue',
        label: 'Minimum Follow-Up Duration',
        value: CONSTANTS.LABELS.TOBE_COMPUTED,
        viewType: 'list',
      });
    }
  }
  if (endPoint.id === 'TTE') {
    const isHazardRateTablePeriods = results.findIndex((el) => el.name === 'hazardRateTablePeriods');
    if (isHazardRateTablePeriods === -1) {
      results = results.filter(
        (el) =>
          ![
            'hazardRateDuration',
            'eventHazardRate',
            'medianSurvivalTime',
            'dropOutHazardrate',
            'medianDropoutTime',
          ].includes(el.name),
      );
      results.push({
        label: 'Hazard Rate(s)',
        name: 'hazardEventRates',
        value: mapper('hazardEventRates', [
          {
            eventHazardRate: formInputs?.eventHazardRate?.value,
            medianSurvivalTime: formInputs?.medianSurvivalTime?.value,
            dropOutHazardrate: formInputs?.dropOutRate?.value,
            medianDropoutTime: formInputs?.medianDropoutTime?.value,
          },
        ]),
        viewType: 'table',
      });
    }

    // Move table fields to last index
    const hazardRateTablePeriodsIndex = results.findIndex((el: any) => el.name === 'hazardRateTablePeriods');
    const accrualRateTablePeriodsIndex = results.findIndex(
      (el: any) => el.name === 'accrualRateTablePeriods',
    );

    if (accrualRateTablePeriodsIndex > -1) {
      results[accrualRateTablePeriodsIndex].label = 'Accrual Rate (s)';
      if (formInputs?.usePieceWiseRelativeAccrualRate?.value === 'Yes') {
        if (accrualRateTablePeriodsIndex > -1) {
          results[
            accrualRateTablePeriodsIndex
          ].label = `Relative ${results[accrualRateTablePeriodsIndex].label}`;
        }
      }
      results.push(results.splice(accrualRateTablePeriodsIndex, 1)[0]);
    }
    if (hazardRateTablePeriodsIndex > -1) {
      results.push(results.splice(hazardRateTablePeriodsIndex, 1)[0]);
    }
  }
  const isSSR = has(formInputs, 'sampleSizeReestimation');
  const isBlindPreserving = get(formInputs, 'ssrMethod.value');
  if (isSSR) {
    if (isBlindPreserving === 'blindPreserving' && endPoint.id === 'DIFFMEANS') {
      const {column, row} = getSSRBlindPreservingTable(formInputs, endPoint.id);
      results.push({
        viewType: 'table',
        label: 'New True Value',
        name: 'ssrBlindPreserving',
        value: {column, row, key: 'ssrBlindPreserving'},
      });
    }
    if (isBlindPreserving === 'blindPreserving' && endPoint.id === 'DIFFBINOM') {
      const {column, row} = getSSRBlindPreservingDiffBinomTable(formInputs, endPoint.id);
      results.push({
        viewType: 'table',
        label: 'New True Value',
        name: 'ssrBlindPreserving',
        value: {column, row, key: 'ssrBlindPreserving'},
      });
    } else {
      const {column, row} = getSSRInputTable(
        endPoint.id,
        formInputs?.interimAnalysesPlanned?.value,
        formInputs?.interimsSsr?.value,
        formInputs?.typesOfTrialDesigns?.value,
      );
      results.push({
        viewType: 'table',
        label: 'New True Value',
        name: 'ssr',
        value: {column, row, key: 'ssr'},
      });
    }
  }
  return results;
};

const transforRCODEResults = (payload: any, endPoint: any) => {
  const modifiedPayload = refinePayload(payload, endPoint);

  const {
    gsSucceeded,
    fixedSucceeded,
    messageFromGS = [],
    messageFromFixed = [],
    messageFromUnknown = [],
    ...rest
  } = modifiedPayload;
  const resultTable = rest ? getResults(omit(rest, ['call.fixed', 'call.gsSurv', 'procedure'])) : null;
  return {
    errorMessageFromGS: messageFromGS,
    errorMessageFromFixed: messageFromFixed,
    errorMessageFromUnknown: messageFromUnknown,
    ...resultTable,
  };
};

const getResults = (payload: any) => {
  return Object.keys(payload).reduce((newFormat: any, key: any) => {
    const {key: nKey, ...props} = mapper(key, payload[key]);
    newFormat[nKey] = {...props};
    return newFormat;
  }, {});
};

const getColumns = (payload: any): Array<string> => {
  return Object.keys(payload[0]);
};

const normalizeResults = (data: any) => {
  return Object.keys(data).reduce((_nItem: any, _item: any) => {
    const value = data[_item];
    const column = getColumns(value);
    const newItem = {key: _item, column, row: value};
    return {...{[_item]: newItem}, ..._nItem};
  }, {});
};

const mapper = (key: any, value: any) => {
  let nKey: string, column;
  nKey = key === 'cum.cross.probs' ? 'boundary' : key;
  switch (key) {
    case 'gamma':
      const isResultNotNested = value instanceof Array;
      const results = isResultNotNested ? {column: getColumns(value), row: value} : normalizeResults(value);
      return {
        key: nKey,
        ...(!isResultNotNested ? {nestedResults: results} : results),
      };
    case 'cum.cross.probs':
      const isResultNotNestedCrossProb = value instanceof Array;
      const resultsCrossProb = isResultNotNestedCrossProb
        ? {column: getColumns(value), row: value}
        : normalizeResults(value);
      return {
        key: 'boundary',
        ...(!isResultNotNestedCrossProb ? {nestedResults: resultsCrossProb} : resultsCrossProb),
      };
    case 'outTabBoundaries':
      const isResultNotNestedOutTab = value instanceof Array;
      const resultsOutTab = isResultNotNestedOutTab
        ? {column: getColumns(value), row: value}
        : normalizeResults(value);
      return {
        key: 'outTabBoundaries',
        ...(!isResultNotNestedOutTab ? {nestedResults: resultsOutTab} : resultsOutTab),
      };
    default:
      column = getColumns(value);
      return {key: nKey, column, row: value};
  }
};

// Changes from AD-3005
const refinePayload = (payload: any, endPoint: any) => {
  if (payload?.expectedTrialParams) {
    payload?.expectedTrialParams.map((el: any) => {
      if (el?.power) {
        el.power = parseToPrecision(Number(el.power) / 100);
      }
      return el;
    });
  }
  if (payload?.powerFromNNormal) {
    payload?.powerFromNNormal.map((el: any) => {
      if (el?.power) {
        el.power = parseToPrecision(Number(el.power) / 100);
      }
      return el;
    });
  }

  // removing as these are not rquired to show in output page
  delete payload?.nominalProbs;
  delete payload?.eventRates;
  return payload;
};

const getSSRInputTable = (
  endPointId: endpointType,
  interimAnalysesPlanned: any,
  interimsSsr: any,
  typesOfTrialDesigns: trialDesignType,
) => {
  const column = [
    '',
    ...Array.from({length: interimAnalysesPlanned}, (_, i) => `Interims ${i + 1}`),
    'Final',
  ];
  let row: any = [];
  switch (endPointId) {
    case 'TTE':
      row = interimsSsr?.map((v: any, index: number) => ({
        estimatedValType: GS_CONSTANTS.ESTIMATED_VAL_TYPE_CONFIG.TTE[index].rowName,
        ...v,
      }));
      break;
    case 'DIFFBINOM':
      row = interimsSsr?.map((v: any, index: number) => ({
        estimatedValType: GS_CONSTANTS.ESTIMATED_VAL_TYPE_CONFIG.DIFFBINOM[index].rowName,
        ...v,
      }));
      break;
    case 'DIFFMEANS':
      row = interimsSsr?.map((v: any, index: number) => ({
        estimatedValType: [
          ...GS_CONSTANTS.ESTIMATED_VAL_TYPE_CONFIG[endPointId],
          ...GS_CONSTANTS.TYPES_OF_TRIAL_FIELDS_SSR[typesOfTrialDesigns?.toLowerCase() as trialDesignType],
        ][index].rowName,
        ...v,
      }));
      break;
    default:
      row = [];
  }
  return {column, row};
};

const getSSRBlindPreservingTable = (formInput: any, endPoint: string) => {
  const interimLookup = formInput?.blindPreservingLookUpInterim?.value;
  const {
    blindPreservingLookSampleSize: {value: blindPreservingLookSampleSizeValue},
    orgInterimSamplSize: {value: orgInterimSamplSizeValue},
    blindPreservingLookSd: {value: blindPreservingLookSdValue},
    orgInterimSmpldev: {value: orgInterimSmpldevValue},
  } = formInput;
  const column = [
    '',
    `User provided Interim ${interimLookup} observations`,
    `Original Interim ${interimLookup}`,
    '',
  ];
  let row: any = [];
  switch (endPoint) {
    case 'DIFFMEANS':
      row = [
        {
          estimatedValType: 'Internal Sample Size at designated interim',
          blindPreservingLookSampleSize: blindPreservingLookSampleSizeValue,
          orgInterimSamplSize: orgInterimSamplSizeValue,
          difference: getChangeDiff(blindPreservingLookSampleSizeValue, orgInterimSamplSizeValue),
        },
        {
          estimatedValType: 'Combined Standard Deviation at the designated Interim',
          blindPreservingLookSd: blindPreservingLookSdValue,
          orgInterimSmpldev: orgInterimSmpldevValue,
          difference: getChangeDiff(blindPreservingLookSdValue, orgInterimSmpldevValue),
        },
      ];
      break;
    default:
      row = [];
  }
  return {column, row};
};
const getSSRBlindPreservingDiffBinomTable = (formInput: any, endPoint: string) => {
  const interimLookup = formInput?.blindPreservingLookUpInterim?.value;
  const {
    blindPreservingInternalPilotSampleSize: {value: blindPreservingInternalPilotSampleSizeValue},
    orgblindPreservingInternalPilotSampleSize: {value: orgblindPreservingInternalPilotSampleSizeValue},
    blindPreservingInternalPilotPropotion: {value: blindPreservingInternalPilotPropotionValue},
    orgblindPreservingInternalPilotPropotion: {value: orgblindPreservingInternalPilotPropotionValue},

    blindPreservingInternalPilotSuccess: {value: blindPreservingInternalPilotSuccessValue},
    orgblindPreservingInternalPilotSuccess: {value: orgblindPreservingInternalPilotSuccessValue},
  } = formInput;
  const column = [
    '',
    `User provided Interim ${interimLookup} observations`,
    `Original Interim ${interimLookup}`,
    '',
  ];
  let row: any = [];
  switch (endPoint) {
    case 'DIFFBINOM':
      row = [
        {
          estimatedValType: 'Internal Pilot Sample Size at designated interim',
          blindPreservingLookSampleSize: blindPreservingInternalPilotSampleSizeValue,
          orgInterimSamplSize: orgblindPreservingInternalPilotSampleSizeValue,
          difference: getChangeDiff(
            blindPreservingInternalPilotSampleSizeValue,
            orgblindPreservingInternalPilotSampleSizeValue,
          ),
        },
        {
          estimatedValType: 'Internal Pilot Proportion at each Interim',
          blindPreservingLookSd: blindPreservingInternalPilotPropotionValue,
          orgInterimSmpldev: orgblindPreservingInternalPilotPropotionValue,
          difference: getChangeDiff(
            blindPreservingInternalPilotPropotionValue,
            orgblindPreservingInternalPilotPropotionValue,
          ),
        },
        {
          estimatedValType: 'Internal Pilot Proportion at each Interim',
          blindPreservingLookSd: blindPreservingInternalPilotSuccessValue,
          orgInterimSmpldev: orgblindPreservingInternalPilotSuccessValue,
          difference: getChangeDiff(
            blindPreservingInternalPilotSuccessValue,
            orgblindPreservingInternalPilotSuccessValue,
          ),
        },
      ];
      break;
    default:
      row = [];
  }
  return {column, row};
};
const getChangeDiff = (blindPreservingLook: number, ssrOrignlInterim: number) => {
  let difference = roundNumber(ssrOrignlInterim - blindPreservingLook, 2);
  return isNaN(difference) ? 0 : `${difference > 0 ? '+' : ''}${difference}`;
};
