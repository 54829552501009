import React, {useEffect, useState} from 'react';
import Table from 'apollo-react/components/Table';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {
  fetchRequest,
  updateProjectOwner,
  getProjectDesigners,
  setProjectRowSelected,
  closeBanner,
  updateTemplateProject,
} from '../../slices/TableSlice';
import {RootState} from '../../../../app/store';
import {selectProjectList, selectProjectTotalCount, userMessage} from '../../selectors/ProjectHomeSelectors';
import {getProjectTableConfig} from './TableConfiguration';
import {getRowsPerPageOptions} from '../../../../common/utils/tableUtils';

import {TableCompProps} from '../../types/ProjectListType';
import {getActiveuser} from '../../selectors/userDetailsSelector';
import Modal from 'apollo-react/components/Modal';
import Banner from 'apollo-react/components/Banner';
import {CONSTANTS} from '../../../../common/constants';
import styles from './Table.module.scss';
import {transformFilters} from '../../../../common/components/TableFilters/utils';
import {IFilters} from '../../../../common/components/TableFilters/types';
import {getSessionStorage} from '../../../../common/utils/useSessionStorage';

export const TenantAdminTable: React.FC<TableCompProps> = ({
  tab,
  showFilters,
  setShowFilters,
  tableKey,
  filters,
  setFilters,
}) => {
  const dispatch = useDispatch();
  const initialAttrs = {
    selectedOwner: {label: null, name: null, value: null},
    selectedRow: {index: -1, projectid: -1},
  };
  let rows = useSelector(selectProjectList) || [];
  const size: number = Number(useSelector(selectProjectTotalCount)) || 0;
  let [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const isLoading = useSelector((state: RootState) => state.projects.isLoading);
  const {userid, tenantid} = useSelector(getActiveuser);
  const [sortedColumn, setSortedColumn] = useState('createdat');
  const [sortOrder, setSortOrder] = useState('desc');
  const [selectedRowData, setSelectedRowData] = useState<any>({});
  const [showConfimOwnerChange, setShowConfimOwnerChange] = useState(false);
  const [showConfirmRetainOwner, setShowConfirmRetainOwner] = useState(false);
  const [showConfirmTemplateProject, setShowConfirmTemplateProject] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState<any>(initialAttrs.selectedOwner);
  const [templateProjectData, setTemplateProjectData] = useState({projectid: '', projectname: ''});
  const showMessage = useSelector(userMessage);
  useEffect(() => {
    if (showMessage === true) {
      setTimeout(() => {
        dispatch(closeBanner({}));
      }, 4000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMessage]);
  // onClicking "Change Owner" in Table Menu
  const onChangeOwnerClick = (rowIdentifier: any) => {
    dispatch(setProjectRowSelected(rowIdentifier));
    dispatch(closeBanner({}));
  };
  // onClicking "Change MarkAsTemplate" in Table Menu
  const onChangeMarkTemplateClick = (rowIdentifier: any) => {
    setTemplateProjectData(rowIdentifier);
    setShowConfirmTemplateProject(true);
  };

  // On clicking "Confirm" in first Warning
  const onConfirmChangeOwner = () => {
    closeConfimation();
    setShowConfirmRetainOwner(true);
  };

  // On closing/clicking "No" in first Warning
  const onCancelChangeOwner = () => {
    closeConfimation();
    dispatch(setProjectRowSelected(initialAttrs.selectedRow));
  };

  // On closing/clicking "No" in first Warning
  const onCancelChangeTemplate = () => {
    closeConfimation();
    dispatch(setProjectRowSelected(initialAttrs.selectedRow));
  };

  // On closing in retain Owner Modal
  const onCancelRetainOwner = () => {
    closeConfimation();
    setShowConfirmRetainOwner(false);
    dispatch(setProjectRowSelected(initialAttrs.selectedRow));
  };

  // To close the first Warning Modal
  const closeConfimation = () => {
    setShowConfirmTemplateProject(false);
    setShowConfimOwnerChange(false);
  };

  // On final confirmation, dispatch req to server
  const dispatchUpateOwner = (retainOldOwner: boolean) => {
    dispatch(setProjectRowSelected(initialAttrs.selectedRow));
    const rowNum = rows.findIndex((e) => !!e.isSelected);
    const row = rows[rowNum];
    dispatch(
      updateProjectOwner({
        userid,
        tenantid,
        projectid: row.projectid,
        oldownerid: row.ownerid,
        newownerid: selectedOwner.value,
        newOwner: selectedOwner.name,
        retainoldowner: retainOldOwner,
        page: page,
        limit: rowsPerPage,
        tab: tab,
        sortedColumn: sortedColumn,
        sortOrder: sortOrder,
      }),
    );
    setShowConfirmRetainOwner(false);
  };

  // On final confirmation, dispatch req to server
  const dispatchTemplateProject = () => {
    dispatch(
      updateTemplateProject({
        userid,
        tenantid,
        page: page,
        limit: rowsPerPage,
        tab: tab,
        sortedColumn: sortedColumn,
        sortOrder: sortOrder,
        projectid: templateProjectData.projectid,
      }),
    );
    setShowConfirmTemplateProject(false);
  };

  // When a new project owner is selected from dropdown.
  const onProjectOwnerSelect = (owner: any) => {
    setSelectedOwner(owner);
    setShowConfimOwnerChange(true);
  };

  // Load the users with role designer for the specified tenant.
  useEffect(() => {
    dispatch(getProjectDesigners({userid, tenantid}));
  }, [dispatch, userid, tenantid]);

  // Load the table
  useEffect(() => {
    dispatch(
      fetchRequest({
        page: ++page,
        limit: rowsPerPage,
        tab,
        userid,
        tenantid,
        sortedColumn,
        sortOrder,
        filters,
      }),
    );
    window.scrollTo(0, 0);
  }, [dispatch, rowsPerPage, tab, page, userid, tenantid, sortedColumn, sortOrder, filters]);

  // Load the users with role designer for the specified tenant.
  useEffect(() => {
    const row = rows.find((row) => !!row.isSelected);
    row && setSelectedRowData(row);
  }, [rows]);

  // Similar to componentWillUnmount
  // Close banner when user moves to another page.
  useEffect(() => {
    return () => {
      dispatch(closeBanner({}));
    };
  }, [dispatch]);

  const onChangeHandler = (
    newRowsPerPage: number,
    _sortedColumn: string,
    _sortOrder: string,
    _filters: IFilters,
    _page = 0,
  ) => {
    setFilters(transformFilters(_filters));
    setSortedColumn(_sortedColumn);
    setSortOrder(_sortOrder);
    if (newRowsPerPage !== rowsPerPage) {
      setPage(0);
      setRowsPerPage(newRowsPerPage);
    } else {
      if (page !== _page) {
        setPage(_page);
      }
    }
  };
  const tenantId = getSessionStorage('tenantid', true);
  const tenantList = useSelector((state: RootState) => state?.user?.details?.tenants, shallowEqual);

  let name = tenantList?.find((tenant: any) => tenant?.id === tenantId)?.tenantNames;

  return (
    <div id="table" className="tableContainer" data-testid="tenantAdminTable">
      <Table
        alignContent="center"
        columns={getProjectTableConfig(tab)}
        classes={{
          tableWrapper: tab === 'tenantAdmin' ? styles.tenantTableWrapper : styles.tableWrapper,
        }}
        key={tableKey}
        showFilters={showFilters}
        showFilterIcon={tab !== 'tenantAdmin'} //   make it true once the filter functionality is implemented for workspace admin projects table
        onToggleFilters={() => setShowFilters(!showFilters)}
        rows={rows.map((row: any, index: number) => ({
          ...row,
          onProjectOwnerSelect: onProjectOwnerSelect,
          onChangeOwnerClick: onChangeOwnerClick,
          setSelectedOwner: setSelectedOwner,
          onChangeMarkTemplateClick: onChangeMarkTemplateClick,
        }))}
        rowsPerPageOptions={getRowsPerPageOptions(size)}
        tablePaginationProps={{
          labelDisplayedRows: ({from, to, count}: any) => (
            <>
              {'Projects '}
              <span style={{whiteSpace: 'nowrap'}}>{`${from}-${to} of ${count}`}</span>
            </>
          ),
        }}
        sortedColumn={sortedColumn}
        sortOrder={sortOrder}
        isLoading={isLoading}
        onChange={onChangeHandler}
        size={size}
        page={page}
        rowsPerPage={rowsPerPage}
      />

      {showConfimOwnerChange && (
        <Modal
          className="confirmationModal"
          open={showConfimOwnerChange}
          onClose={onCancelChangeOwner}
          title="Warning"
          variant="warning"
          message={CONSTANTS.INFORMATIONAL_MESSAGES.CONFIRM_CHANGE_OWNER(
            selectedOwner.name,
            selectedRowData.projectname,
          )}
          buttonProps={[
            {label: CONSTANTS.LABELS.CANCEL, onClick: onCancelChangeOwner, 'data-testid': 'confirmChangeNo'},
            {label: CONSTANTS.LABELS.OK, onClick: onConfirmChangeOwner, 'data-testid': 'confirmChangeYes'},
          ]}
        ></Modal>
      )}

      {showConfirmRetainOwner && (
        <Modal
          className="confirmationModal"
          open={showConfirmRetainOwner}
          onClose={onCancelRetainOwner}
          title="Warning"
          variant="warning"
          message={CONSTANTS.INFORMATIONAL_MESSAGES.CONFIRM_RETAIN_OWNER(
            selectedRowData.owner,
            selectedRowData.projectname,
          )}
          buttonProps={[
            {
              label: CONSTANTS.LABELS.NO,
              onClick: () => dispatchUpateOwner(false),
              'data-testid': 'retainOwnerNo',
            },
            {
              label: CONSTANTS.LABELS.YES,
              onClick: () => dispatchUpateOwner(true),
              'data-testid': 'retainOwnerYes',
            },
          ]}
        ></Modal>
      )}
      {showConfirmTemplateProject && (
        <Modal
          className="confirmationModal"
          open={showConfirmTemplateProject}
          onClose={onCancelChangeTemplate}
          title="Mark as Template"
          message={CONSTANTS.INFORMATIONAL_MESSAGES.CONFIRM_CHANGE_TEMPLATE(
            templateProjectData.projectname,
            name,
          )}
          buttonProps={[
            {
              label: CONSTANTS.LABELS.CANCEL,
              onClick: onCancelChangeTemplate,
              'data-testid': 'confirmChangeTemplateNo',
            },
            {
              label: CONSTANTS.LABELS.CONFIRM,
              onClick: dispatchTemplateProject,
              'data-testid': 'confirmChangeTemplateYes',
            },
          ]}
        ></Modal>
      )}
      <Banner
        variant="success"
        open={showMessage}
        onClose={() => dispatch(closeBanner({}))}
        message={
          showConfirmRetainOwner
            ? CONSTANTS.INFORMATIONAL_MESSAGES.SUCCESS_CHANGE_OWNER(
                selectedOwner.name,
                selectedRowData.projectname,
              )
            : CONSTANTS.INFORMATIONAL_MESSAGES.SUCCESS_CHANGE_TEMPLATE(templateProjectData.projectname, name)
        }
        style={{top: '57px'}}
      />
    </div>
  );
};
