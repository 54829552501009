import {call, put, takeEvery} from 'redux-saga/effects';
import {callApi} from '../../../services/api';
import {ENDPOINTS} from '../../../services/endpoint';
import {addUser as addUserAction, addUserSuccess, addUserError} from '../slices/AddUserSlice';

export function* watchAddUser() {
  yield takeEvery(addUserAction, addUser);
}

export function* addUser({payload}: any) {
  try {
    const {userid, tenantid, userData} = payload;

    const url = `${ENDPOINTS.ADD_USER_TO_TENANT}?userid=${userid}&tenantid=${tenantid}`;
    /* istanbul ignore next */
    const body = {
      qid: userData.userId || undefined,
      email: userData.email,
      role: userData.role,
      firstName: userData.firstName,
      lastName: userData.lastName,
      isInternal: userData.isIqviaUser ? 1 : 0,
    };
    const data = yield call(callApi, url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(body),
    });
    /* istanbul ignore next */
    if (data) {
      yield put(addUserSuccess(data));
    }
  } catch (error) {
    yield put(addUserError(JSON.stringify(error?.error)));
  }
}
